import * as ko from 'knockout';
import { session } from '../session';
import { uuid4 } from '../utils/uuid';
import * as Sentry from '@sentry/browser';
import { FeedbackData } from './ai_chat_modal';

const template = require('raw-loader!../../templates/components/ai_chat.html').default;

/** Data to be sent with the feedback event. */
export interface FeedbackContextData {
  tags: Record<string, any>;
  contexts: Record<string, any>;
}

/** View with the AI button and chat modal. */
class AiChatViewModel {
  private conversationId: string;

  /** Provides data to be sent with the feedback */
  private getFeedbackContextData: () => FeedbackContextData;

  /** Whether the chat modal is open or closed */
  isOpen = ko.observable<boolean>(false);

  /** Function that provides context data for the AI response. */
  getPageContext: () => Record<string, any>;

  constructor(params: {
    getPageContext: () => Record<string, any>;
    getFeedbackContextData: () => FeedbackContextData;
  }) {
    this.getPageContext = params.getPageContext;
    this.getFeedbackContextData = params.getFeedbackContextData;
    this.conversationId = uuid4();
  }

  /** Whether the assistant is enabled at the page. */
  isEnabled(): boolean {
    return !!session.tenant()?.ai_enabled;
  }

  /** Captures a user feedback event about an AI response. */
  sendFeedback = ({
    feedback,
    previousFeedback,
    messageContent,
    userQuestion,
    index,
    messageCount,
  }: FeedbackData) => {
    const { tags, contexts } = this.getFeedbackContextData();
    Sentry.captureEvent({
      message: 'AI Assistant Feedback',
      level: 'info',
      tags: {
        feedback: feedback,
        changedFrom: previousFeedback,
        messageType: 'ai_response',
        conversationId: this.conversationId,
        tenantSlug: session.tenant()?.slug,
        ...tags,
      },
      extra: {
        messageContent: messageContent,
        userQuestion: userQuestion,
        messageId: `${this.conversationId}_${index}`,
        timestamp: new Date().toISOString(),
      },
      user: {
        email: session.getEmail(),
      },
      contexts: {
        chat: {
          messageCount: messageCount,
          positionInThread: index,
        },
        ...contexts,
      },
    });
  };
}

export const aiChat = {
  name: 'ai-chat',
  viewModel: AiChatViewModel,
  template: template,
};

ko.components.register(aiChat.name, {
  viewModel: aiChat.viewModel,
  template: aiChat.template,
});
