import * as ko from 'knockout';

const template = require('raw-loader!../../templates/components/ai_assistant_button.html').default;

/**
 * Manages the AI button that opens the chat modal.
 */
class AiAssistantButtonViewModel {
  /** Whether the assistant is open or closed */
  isOpen: ko.Observable<boolean>;

  /** Whether the assistant is enabled at the page. */
  isEnabled: ko.Observable<boolean>;

  /** Message to show when AI is disabled */
  enableSuggestion: string;

  constructor(params: { isOpen: ko.Observable<boolean>; isEnabled: ko.Observable<boolean> }) {
    this.isOpen = params.isOpen;
    this.isEnabled = params.isEnabled;
  }

  toggle = () => {
    if (this.isEnabled()) {
      this.isOpen(!this.isOpen());
    }
  };
}

export const aiAssistantButton = {
  name: 'ai-assistant-button',
  viewModel: AiAssistantButtonViewModel,
  template,
};

ko.components.register(aiAssistantButton.name, {
  viewModel: aiAssistantButton.viewModel,
  template: aiAssistantButton.template,
});
