import * as ko from 'knockout';
import i18n from '../i18n';
import * as promptsApi from '../api/v2/prompts';

import * as showdown from 'showdown';

const template = require('raw-loader!../../templates/components/ai_chat_modal.html').default;

export type UserFeedback = 'positive' | 'negative' | 'neutral';

/** A message in the chat. */
interface Message {
  messageHtml: string;
  isUser: boolean;
  feedback?: UserFeedback;
  loading: boolean;
}

/** Feedback event data sent when a user likes / dislikes an answer. */
export interface FeedbackData {
  feedback: UserFeedback;
  previousFeedback: UserFeedback | null;
  messageContent: string;
  userQuestion: string;
  index: number;
  messageCount: number;
}

class AiChatModalViewModel {
  private adaptiveModalSize: AdaptiveModalSize;

  /** Markdown - HTML converter */
  private converter: showdown.Converter;

  private static readonly INITIAL_AI_MESSAGE = {
    messageHtml: i18n.t(
      "Hi! I'm Q, your personal AI analyst. Ask me questions about the trial data on this screen."
    )(),
    isUser: false,
    loading: false,
  };

  private static readonly THINKING_MESSAGE = {
    messageHtml: i18n.t('Thinking...')(),
    isUser: false,
    loading: true,
  };

  private static readonly ERROR_MESSAGE = {
    messageHtml: i18n.t('Sorry, I encountered an error processing your request. Please try again.')(),
    isUser: false,
    loading: false,
  };

  regenerateTitle = i18n.t('Regenerate')();
  copyTitle = i18n.t('Copy')();
  likeTitle = i18n.t('Like')();
  dislikeTitle = i18n.t('Dislike')();

  /** Whether the chat modal is open or closed. */
  isOpen: ko.Observable<boolean>;

  /** Function that provides context data for the AI response. */
  getPageContext: () => Record<string, any>;

  /** Callback to handle feedback from the user. */
  onFeedback: (feedbackData: FeedbackData) => void;

  /** The message being typed by the user. */
  currentMessage = ko.observable<string>('');

  messagePlaceholder = i18n.t('Write your question here')();

  isSendingMessage = ko.observable<boolean>(false);

  /** The messages exchanged in the chat. */
  messages = ko.observableArray<Message>([AiChatModalViewModel.INITIAL_AI_MESSAGE]);

  /** Whether the chat is scrolled to the bottom. */
  private isScrolledToBottom = true;

  constructor(params: {
    isOpen: ko.Observable<boolean>;
    getPageContext: () => Record<string, any>;
    onFeedback: (feedbackData: FeedbackData) => void;
  }) {
    this.isOpen = params.isOpen;
    this.getPageContext = params.getPageContext;
    this.onFeedback = params.onFeedback;

    this.adaptiveModalSize = new AdaptiveModalSize(
      document.querySelector('.ai-chat-modal') as HTMLElement,
      this.onModalResized
    );

    this.converter = new showdown.Converter({
      tables: true,
    });
  }

  private onModalResized = () => {
    if (this.isScrolledToBottom) {
      this.scrollToBottom();
    }
  };

  private scrollToMessage(messageIndex: number) {
    // Use setTimeout to ensure this runs after DOM updates
    setTimeout(() => {
      const messagesContainer = document.querySelector('.ai-chat-messages');
      const messageElements = messagesContainer?.querySelectorAll('[data-message-index]');
      if (messageElements && messageElements[messageIndex]) {
        messageElements[messageIndex].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }

  private scrollToBottom() {
    this.scrollToMessage(this.messages().length - 1);
  }

  startResize = (direction: string, data: any, event: MouseEvent) => {
    // Track if we're scrolled to bottom before resizing
    const messagesContainer = document.querySelector('.ai-chat-messages');
    if (messagesContainer) {
      this.isScrolledToBottom =
        messagesContainer.scrollHeight - messagesContainer.scrollTop <= messagesContainer.clientHeight + 10;
    }

    this.adaptiveModalSize.startResize(direction, data, event);
  };

  handleTextareaKeydown = (data: any, event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Prevent the default action (newline)
      event.preventDefault();
      this.sendCurrentMessage();
      return false;
    }
    // Let Shift+Enter create a new paragraph
    return true;
  };

  /** Sends the current message to the AI and handles the response. */
  sendCurrentMessage = async () => {
    const message = this.currentMessage().trim();
    if (!message || this.isSendingMessage()) return;

    // Clear the input field
    this.currentMessage('');

    // Add user message to chat
    this.messages.push({
      messageHtml: message,
      isUser: true,
      loading: false,
    });
    this.scrollToBottom();

    // Add loading message for AI response
    const loadingMessageIndex = this.messages().length;
    this.messages.push(AiChatModalViewModel.THINKING_MESSAGE);
    this.scrollToBottom();

    await this.sendMessage(message, this.getPageContext(), loadingMessageIndex);
  };

  /**
   * Returns the chat history for the AI context.
   * @param {number} until The exclusive end index of the last message to include.
   * @returns
   */
  private getChatHistory(until?: number): { chat_history: promptsApi.ChatMessage[] } {
    return {
      chat_history: this.messages()
        .slice(0, until)
        .map((message) => ({
          content: this.convertToMarkdown(message.messageHtml),
          sender: message.isUser ? 'user' : 'model',
        })),
    };
  }

  private async sendMessage(message: string, context: Record<string, any>, responseIndex: number) {
    this.isSendingMessage(true);
    const response = await this.getResponse(message, {
      ...this.getChatHistory(responseIndex),
      ...context,
    });
    this.updateMessage(responseIndex, response);
    this.isSendingMessage(false);
    this.scrollToMessage(responseIndex - 1);
  }

  private async getResponse(
    message: string,
    contextData: promptsApi.AiQueryRequest['query_context']
  ): Promise<Message> {
    try {
      const response = await promptsApi.processAiQuery({
        query: message,
        query_context: contextData,
      });

      return {
        messageHtml: this.convertToHtml(response.answer),
        isUser: false,
        loading: false,
      };
    } catch (error) {
      console.error('Error calling AI API:', error);

      return AiChatModalViewModel.ERROR_MESSAGE;
    }
  }

  /** Regenerates the AI response for a message. */
  async regenerateMessage(index: number) {
    if (index < 1 || index >= this.messages().length) {
      return;
    }

    if (this.messages()[index].isUser) {
      return;
    }

    this.updateMessage(index, AiChatModalViewModel.THINKING_MESSAGE);

    const contextData = this.getPageContext() || {
      'Previous AI Response': this.convertToMarkdown(this.messages()[index].messageHtml),
    };

    const userMessage = this.convertToMarkdown(this.messages()[index - 1].messageHtml);

    await this.sendMessage(userMessage, contextData, index);
  }

  copyMessage(index: number, event: MouseEvent) {
    const message = this.messages()[index].messageHtml;
    this.copyToClipboard(this.convertToMarkdown(message));
    this.replaceIconTemporarily(event.target as HTMLElement, 'task');
  }

  private convertToMarkdown(html: string) {
    return this.converter.makeMarkdown(html);
  }

  private convertToHtml(markdown: string) {
    return this.converter.makeHtml(markdown);
  }

  private copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  private replaceIconTemporarily(element: HTMLButtonElement | HTMLElement, newIcon: string) {
    const icon = element.tagName.toLowerCase() === 'i' ? element : element.querySelector('i');
    if (!icon) {
      return;
    }
    const originalIcon = icon.textContent;
    icon.textContent = newIcon;
    setTimeout(() => {
      icon.textContent = originalIcon;
    }, 1500);
  }

  toggleFeedback = (index: number, event: 'up' | 'down') => {
    const message = this.messages()[index];
    if (message.isUser) {
      return;
    }
    const previousFeedback = message.feedback;
    const newFeedback = this.evaluateFeedback(event, previousFeedback);

    this.updateMessage(index, {
      ...message,
      feedback: newFeedback,
    });

    const userQuestion = index > 0 ? this.messages()[index - 1].messageHtml : '';

    this.onFeedback({
      feedback: newFeedback,
      previousFeedback,
      messageContent: message.messageHtml,
      userQuestion,
      index,
      messageCount: this.messages().length,
    });
  };

  private evaluateFeedback(event: 'up' | 'down', previousFeedback: UserFeedback): UserFeedback {
    switch (event) {
      case 'up':
        return previousFeedback === 'positive' ? 'neutral' : 'positive';
      case 'down':
        return previousFeedback === 'negative' ? 'neutral' : 'negative';
    }
  }

  private updateMessage(index: number, newMessage: Message) {
    this.messages.splice(index, 1, newMessage);
  }

  hasPositiveFeedback = (index: number) => {
    const message = this.messages()[index];
    return message.feedback === 'positive';
  };

  hasNegativeFeedback = (index: number) => {
    const message = this.messages()[index];
    return message.feedback === 'negative';
  };
}

/** Allows resizing HTML modal elements. */
class AdaptiveModalSize {
  private isResizing = false;
  private resizeDirection = '';
  private initialX = 0;
  private initialY = 0;
  private initialWidth = 0;
  private initialHeight = 0;

  constructor(private modalElement: HTMLElement, private onResized: () => void) {
    this.modalElement = modalElement;
  }

  startResize = (direction: string, data: any, event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    this.isResizing = true;
    this.resizeDirection = direction;

    const rect = this.modalElement.getBoundingClientRect();

    this.initialX = event.clientX;
    this.initialY = event.clientY;
    this.initialWidth = rect.width;
    this.initialHeight = rect.height;

    // Add event listeners for mouse move and up
    document.addEventListener('mousemove', this.doResize);
    document.addEventListener('mouseup', this.stopResize);
  };

  doResize = (event: MouseEvent) => {
    if (!this.isResizing) return;

    const deltaX = event.clientX - this.initialX;
    const deltaY = event.clientY - this.initialY;

    // Minimum chat dimensions
    const minWidth = 300;
    const minHeight = 350;

    // Maximum dimensions
    const maxWidth = window.innerWidth - 100;
    const maxHeight = window.innerHeight - 140;

    // Handle different resize directions
    switch (this.resizeDirection) {
      case 'w':
        const newWidth = Math.max(minWidth, Math.min(maxWidth, this.initialWidth - deltaX));
        this.modalElement.style.width = `${newWidth}px`;
        break;

      case 'n':
        const newHeight = Math.max(minHeight, Math.min(maxHeight, this.initialHeight - deltaY));
        this.modalElement.style.height = `${newHeight}px`;
        break;

      case 'nw':
        const newWidthNW = Math.max(minWidth, Math.min(maxWidth, this.initialWidth - deltaX));
        const newHeightNW = Math.max(minHeight, Math.min(maxHeight, this.initialHeight - deltaY));

        this.modalElement.style.width = `${newWidthNW}px`;
        this.modalElement.style.height = `${newHeightNW}px`;
        break;
    }

    this.onResized();
  };

  stopResize = () => {
    this.isResizing = false;
    document.removeEventListener('mousemove', this.doResize);
    document.removeEventListener('mouseup', this.stopResize);
  };
}

export const aiChatModal = {
  name: 'ai-chat-modal',
  viewModel: AiChatModalViewModel,
  template: template,
};

ko.components.register(aiChatModal.name, {
  viewModel: aiChatModal.viewModel,
  template: aiChatModal.template,
});
