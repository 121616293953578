import * as ko from 'knockout';
import { setupErrorLogging } from './error_logging';
setupErrorLogging();

import * as i18n from './i18n';
i18n;

// force to import files that are used for global side effects (e.g. registering a KO binding/component)
import * as session from './session';
session;

import * as koGoogleSignIn from './ko_bindings/google_sign_in';
koGoogleSignIn;
import * as koValidationError from './ko_bindings/validation_error';
koValidationError;
import * as koMaterialDropdown from './ko_bindings/material_dropdown';
koMaterialDropdown;
import * as koMaterialSelect from './ko_bindings/material_select';
koMaterialSelect;
import * as koDatePicker from './ko_bindings/date_picker';
koDatePicker;
import * as koTenantHref from './ko_bindings/tenant_href';
koTenantHref;
import * as koValidation from 'knockout.validation';
koValidation;
import * as geoPtValidation from './ko_bindings/geo_pt_validation';
geoPtValidation;
import * as slugValidation from './ko_bindings/slug_validation';
slugValidation;
import * as koFormattedDate from './ko_bindings/formatted_date';
koFormattedDate;
import * as koFormattedBoolean from './ko_bindings/formatted_boolean';
koFormattedBoolean;
import * as koTimeAgo from './ko_bindings/time_ago';
koTimeAgo;
import * as koChart from './ko_bindings/d3chart';
koChart;
import * as koBarChart from './ko_bindings/bar_chart';
koBarChart;
import * as kQuadScatterChart from './ko_bindings/quad_scatter_chart';
kQuadScatterChart;
import * as regularChart from './ko_bindings/chart';
regularChart;
import * as kSpiderChart from './ko_bindings/spider_chart';
kSpiderChart;
import * as koArrow from './ko_bindings/arrow';
koArrow;
import * as koI18nText from './ko_bindings/i18n_text';
koI18nText;
import * as koMap from './ko_bindings/map';
koMap;
import * as koMapLocation from './ko_bindings/map_location';
koMapLocation;
import * as koMapGoTo from './ko_bindings/map_go_to';
koMapGoTo;
import * as koMapZone from './ko_bindings/map_zone';
koMapZone;
import * as koFormattedNumber from './ko_bindings/formatted_number';
koFormattedNumber;
import * as koOnClickOutside from './ko_bindings/on_click_outside';
koOnClickOutside;
import * as koStripePayment from './ko_bindings/stripe_payment';
koStripePayment;
import * as koStageChangeChart from './ko_bindings/stage_change_chart';
koStageChangeChart;
import * as koRawDOM from './ko_bindings/raw_dom';
koRawDOM;
import * as koTextAreaAutoResize from './ko_bindings/text_area_auto_resize';
koTextAreaAutoResize;
import * as koFirebaseLogin from './ko_bindings/firebase_login';
koFirebaseLogin;
import * as koSafeHref from './ko_bindings/safe_href';
koSafeHref;
import * as koIndeterminateValue from './ko_bindings/indeterminate_value';
koIndeterminateValue;

import * as basicWidgets from './components/basic_widgets';
basicWidgets;
import * as animatedLogo from './components/animated_logo';
animatedLogo;
import * as bulkActionsPanel from './components/bulk_actions_panel';
bulkActionsPanel;
import * as bulkRemoveAction from './components/bulk_remove_action';
bulkRemoveAction;
import * as koNavBar from './components/navigation_bar';
koNavBar;
import * as formSelectSearch from './components/form_select_search';
formSelectSearch;
import * as formDateTimeInput from './components/form_date_time_input';
formDateTimeInput;
import * as formNestedEntities from './components/form_nested_entities';
formNestedEntities;
import * as formTabbedEntities from './components/form_tabbed_entities';
formTabbedEntities;
import * as i18nTextInput from './components/i18n_text_input';
i18nTextInput;
import * as mapEdit from './components/map_edit';
mapEdit;
import * as valuesMeta from './components/values_meta';
valuesMeta;
import * as measurementMetaEdit from './components/measurement_meta_edit';
measurementMetaEdit;
import * as measurementMetaEditFormula from './components/measurement_meta_edit_formula';
measurementMetaEditFormula;
import * as listLoader from './components/list_loader';
listLoader;
import * as attributeValue from './components/attribute_value';
attributeValue;
import * as baseDimensionRecordEdit from './components/base_dimension_record_edit';
baseDimensionRecordEdit;
import * as progressBar from './components/progress_bar';
progressBar;
import * as datasetDimensionMetasEdit from './components/dataset_dimension_metas_edit';
datasetDimensionMetasEdit;
import * as dimensionsTable from './components/dimensions_table';
dimensionsTable;
import * as dimensionAdvancedSearch from './components/dimension_advanced_search';
dimensionAdvancedSearch;
import * as userAdvancedSearch from './components/user_advanced_search';
userAdvancedSearch;
import * as trialAdvancedSearch from './components/trial_advanced_search';
trialAdvancedSearch;
import * as traitAdvancedSearch from './components/trait_advanced_search';
traitAdvancedSearch;
import * as importEntities from './components/import_entities';
importEntities;
import * as dataEntryPermissionsEdit from './components/data_entry_permissions_edit';
dataEntryPermissionsEdit;
import * as factsTable from './components/facts_table';
factsTable;
import * as chartGroupList from './components/chart_group_list';
chartGroupList;
import * as trialFacts from './screens/trial_facts';
trialFacts;
import * as factEdit from './screens/fact_edit';
factEdit;
import * as siteActivityStatus from './components/site_activity_status';
siteActivityStatus;
import * as formSelectCV from './components/form_select_cv';
formSelectCV;

import * as trialBasicData from './components/trial_wizard/trial_basic_data';
trialBasicData;
import * as trialTestSubject from './components/trial_wizard/trial_test_subject';
trialTestSubject;
import * as trialTreatments from './components/trial_wizard/trial_treatments';
trialTreatments;
import * as trialSites from './components/trial_wizard/trial_sites';
trialSites;
import * as trialExperimentalDesign from './components/trial_wizard/trial_experimental_design';
trialExperimentalDesign;
import * as trialLayout from './components/trial_wizard/trial_layout';
trialLayout;
import * as trialAssessments from './components/trial_wizard/trial_assessments';
trialAssessments;
import * as scanCode from './components/trial_wizard/scan_code';
scanCode;
import * as trialVisits from './components/trial_wizard/trial_visits';
trialVisits;
import * as trialActivation from './components/trial_wizard/trial_activation';
trialActivation;
import * as selectDataset from './components/trial_wizard/select_dataset';
selectDataset;
import * as selectFromLibrary from './components/trial_wizard/select_from_library';
selectFromLibrary;
import * as map from './components/map';
map;
import * as listHeader from './components/list_header';
listHeader;
import * as listFilters from './components/list_filters';
listFilters;
import * as moreDropdownMenu from './components/more_dropdown_menu';
moreDropdownMenu;
import * as addCVStage from './components/add_cv_stage';
addCVStage;
import * as tppFactsPopup from './components/tpp_facts_popup';
tppFactsPopup;
import * as nameEdit from './screens/name_edit';
nameEdit;
import * as normalizationSelect from './components/normalization_select';
normalizationSelect;
import * as columnSelect from './components/columns_select';
columnSelect;
import * as userLog from './components/user_log';
userLog;
import * as reviewValue from './components/review_value';
reviewValue;
import * as editMeasurementChoices from './components/edit_measurement_choices';
editMeasurementChoices;
import * as driverAnalysisLegend from './components/driver_analysis_legend';
driverAnalysisLegend;
import * as subscriptionWeatherInfo from './components/subscription_weather_info';
subscriptionWeatherInfo;
import * as stageChangeChart from './components/stage_change_chart';
stageChangeChart;
import * as dataEntryBar from './data_entry/edit/data_entry_bar';
dataEntryBar;
import * as scheduledVisitEditBase from './components/scheduled_visit_edit_base';
scheduledVisitEditBase;
import * as aiAssistantButton from './components/ai_assistant_button';
aiAssistantButton;
import * as aiChat from './components/ai_chat';
aiChat;
import * as aiChatModal from './components/ai_chat_modal';
aiChatModal;

import { setupRoutes } from './routes';
import { setupApp } from './app';
import { getMenu } from './menu';

ko.validation.init({
  insertMessages: false,
});

i18n.default.onTranslationReady(() => {
  session.session.init({
    apiKey: SERVER_INFO.FIREBASE_API_KEY,
    authDomain: SERVER_INFO.FIREBASE_AUTH_DOMAIN,
  });
  setupRoutes();
  setupApp(getMenu);
  i18n.default.setupKnockoutValidationLocalization();
  // this line needed so translated custom validators work e.g. ko.validation.rules
  ko.validation.registerExtenders();
});

// DEBUG
(<any>window).ko = ko;
