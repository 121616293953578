import * as ko from 'knockout';

import { BaseLoadingScreen } from './base_loading_screen';
import * as trialsApi from '../api/trials';
import * as datasetsApi from '../api/datasets';
import * as dashboardApi from '../api/dashboard';
import * as sitesApi from '../api/sites';
import * as usersApi from '../api/users';
import {
  trialFacts as getTrialFacts,
  trialStaffUsers as getTrialStaffUsers,
  visitsForFacts as getVisitsForFacts,
} from '../api/v2/trial';
import * as dimensionsApi from '../api/dimensions';
import * as customReportsApi from '../api/custom_report';
import { TestSubjectDimension } from '../api/v2/interfaces';
import { UnitData, list as listUnits } from '../api/units';
import { DatasetFacts, factSummaryToGeoJSON, siteSummaryToGeoJSON } from '../components/facts_table';
import { session } from '../session';
import { BarChartConfig } from '../ko_bindings/bar_chart';
import { weather, exportWeather } from '../api/trials';
import { weatherEnabled, canEditTrial, advancedWeatherEnabled, canViewTrialAnalysis } from '../permissions';
import { setUnitPreferenceForTrait } from '../services/unit_preferences';
import { createUnitDropdownOption, createSelectOption } from '../utils/trial_facts';
import { groupBy, isEqual, isNil, truncate } from 'lodash';
import { getTrialTestSubjectDimensionMetas } from './../api/v2/trial';
import { openFactEditAndHistoryDialog } from '../components/fact_edit_and_history_dialog';
import { Context as DatalabelsContext } from 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { defaultTitle } from '../ko_bindings/chart';

import {
  downloadBlob,
  el,
  isIE11,
  KEYCODE_ENTER,
  KEYCODE_DOWN,
  KEYCODE_UP,
  KEYCODE_RIGHT,
  KEYCODE_LEFT,
  KEYCODE_TAB,
  getColor,
  STORAGE_KEYS,
  retrieveOnSiteAndOffSitePoints,
  adjustDateToTimezoneOffset,
} from '../utils';
import i18n from '../i18n';
import { Trial } from '../models/trial';
import { SavedPageFilter } from '../models/saved_page_filter';
import { TrialState } from '../models/TrialState';
import { translate } from '../i18n_text';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { viewImages } from '../components/view_image';
import { viewVideo } from '../components/view_video';
import { showApplyUnitChangesToAllColumnsDialog } from '../components/apply_unit_changes_to_all_columns_dialog';
import { openEditDerivedTrait } from '../components/derived_trait_edit_popup';
import {
  DataEntryEditModel,
  DataEntryMoveDirection,
  OverviewSparseData,
} from '../data_entry/edit/data_entry_edit_model';
import { removeIfChild } from '../utils/dom';
import { FilterDelegate } from '../components/list_filters';
import { DimensionData } from '../api/dimensions';
import * as savedPageFilterApi from '../api/saved_page_filter';
import { deflateList } from '../api/serialization';
import {
  InteractionPlotDimension,
  TraitCompareToControlResponse,
  TraitDataInteractionPlot,
  TraitDataResponse,
  TraitForPlotEntity,
} from '../api/tpps';
import { exportTechnicalScorecardFile, fetchTechnicalScorecard } from '../api/dashboard';
import { formatValue, getMultiPicturesUrls } from '../components/overview_value';
import { factChangeReasons } from '../models/attribute_meta';
import { getColorForPValue } from '../utils/stats';
import { ScheduledVisitData } from '../api/scheduled_visits';
import { MeasurementMetaData } from '../api/v2/interfaces';
import {
  ChartConfiguration,
  PointStyle,
  LegendItem,
  ChartEvent,
  TooltipItem,
  ChartDataset,
  ScatterDataPoint,
} from 'chart.js';
import { getTrialTraitsForStats, TraitItem } from '../api/v2/measurement_meta';
import { ListRequestParams } from '../api/request';
import { Chart } from 'chart.js';
import 'gridjs/dist/theme/mermaid.css';
import { selectLocationZonePopup } from '../components/map_zone';
import { confirmDialog } from '../components/confirm_dialog';
import { OverviewExporter } from '../models/overview_exporter';
import { FeedbackContextData } from '../components/ai_chat';

interface SelectedTraitItem {
  name: string;
  pk: number;
  unit: string | null;
  name_with_unit?: string;
  scheduled_visit: TraitItem['scheduled_visits'][0] & {
    typeOfVisitGrouping: ko.Observable<VisitGroupingType>;
  };
}

interface SelectedGroupedTrait {
  traits: SelectedTraitItem[];
  scheduled_visit: TraitItem['scheduled_visits'][0] & {
    typeOfVisitGrouping: ko.Observable<VisitGroupingType>;
  };
}

const MEASUREMENT_COUNT_LIMIT = 100_000; // Amount to automatically add Visit and Site to chosen filters on initialization

let template = require('raw-loader!../../templates/trial_facts.html').default;

export interface TrialFactsScreenDelegate {
  onMainTabSelected(): void;
}

export enum OverviewClickAction {
  EDIT = 'edit',
  HISTORY = 'history',
}

enum Tab {
  MAIN,
  MAP,
  WEATHER,
  OVERVIEW,
  ANALYSIS,
  OBS_GROUP,
  CUSTOM_REPORTS,
  REPORT_DOCUMENT,
}

enum AnalysisTabs {
  SCATTER = 'Scatter',
  STATS = 'Stats',
  INTERACTION = 'Interaction',
  BAR = 'Bar',
  COMPARE_TO_CONTROL = 'CompareToControl',
}

enum InteractionGraphType {
  SITES_INTERACTION = 'Sites',
  FACTORS_INTERACTION = 'Factors',
}

enum SiteGeoZoneTypeToDisplay {
  GYGA_CLIMATE_ZONE = 'GYGA Climate',
  GYGA_TED_ZONE = 'GYGA TED',
  NONE = 'None',
}

enum MapTabType {
  OVERVIEW = 'Overview',
  PLAYBACK = 'Playback',
}

enum VisitGroupingType {
  FIRST_VISIT = 'first_visit',
  ALL_VISITS = 'all_visits',
}

const DerivedValueTypes = ['number_derived', 'date_derived'];

export type DataCollectionStatus = 'done' | 'in_progress' | 'late' | 'calculating';

interface SitesGroupedByGeoZones {
  [zoneCode: number | string]: { sites: any[]; zoneId: number | string };
}

class TrialFactsScreen extends BaseLoadingScreen implements OverviewTableViewDelegate {
  trialId: string = null;
  readOnly: boolean = true;
  AnalysisTabs = AnalysisTabs;
  MapTabType = MapTabType;
  TrialStatsType = dashboardApi.TrialStatsType;
  mapVisualizationFeatureEnabled = session.tenant() && session.tenant().map_visualization_enabled;
  enableWeather = weatherEnabled();
  enableAdvancedWeather = advancedWeatherEnabled();
  units: UnitData[];
  measurementMetasUnits: Record<number, number>;

  isObservationGroupTabEnabled = ko.observable<boolean>(false);
  isAnalysisTabEnabled = ko.observable<boolean>(false);
  isCustomReportsFeatureEnabled = session.tenant().custom_reports_enabled;
  isReportsFeatureEnabled = session.tenant().reports_enabled;

  private trial: Trial;
  public delegate: TrialFactsScreenDelegate;

  mainTabTitle: string;
  private selected = ko.observable<Tab>(Tab.MAIN);
  datasetsFacts = ko.observableArray<DatasetFacts>();
  dataset = ko.observable<DatasetFacts>();
  markersPopUps = ko.observableArray<() => void>();
  offSiteObservationsNumber = ko.observable<number>(null);
  sitesMapPositions = ko.observableArray<datasetsApi.GeoJSON>();
  playbackIsEnabled = ko.observable<boolean>(true);
  lastRenderedFactScheduledVisitId = ko.observable<number>(null);

  loadingMap = ko.observable(false);
  mapPoints = ko.observableArray<datasetsApi.GeoJSON>();
  currentPlaybackMapPoints = ko.observableArray<datasetsApi.GeoJSON>([]);
  trialLocationsSummary = ko.observable<datasetsApi.TrialLocationsSummary>();
  mapProgress = ko.observable(0);
  fromDateFilterValue = ko.observable<Date>();
  toDateFilterValue = ko.observable<Date>();
  selectedMapTabType = ko.observable<MapTabType>(MapTabType.OVERVIEW);

  loadingWeather = ko.observable(false);
  loadingExternal = ko.observable(false);
  loadingAll: ko.Computed<boolean>;

  weatherChartsConfig = ko.observableArray<BarChartConfig>();

  exportingWeatherText = i18n.t('Exporting...')();
  exportingWeather = ko.observable(false);
  exportingTechnicalScorecard = ko.observable(false);

  canEditTrials: boolean;

  loadingOverview = ko.observable<'initial' | 'update' | 'loaded'>('initial');
  isLoadingData = ko.observable(false);
  isTechnicalScorecardEnabled = ko.observable(false);

  private overviewData: dashboardApi.OverviewData = null;
  private overviewSparseData: OverviewSparseData = null;

  private showIconsForChoices = [
    { name: i18n.t('Comments')(), id: 'comments', selected_by_default: true },
    { name: i18n.t('Photos/Documents')(), id: 'photos_and_documents', selected_by_default: true },
    { name: i18n.t('Changed data (history)')(), id: 'changed_data', selected_by_default: true },
  ];

  private sitesFilter = ko.observableArray<DimensionData>();
  private scheduledVisitFilter = ko.observableArray<ScheduledVisitData>();
  private traitsFilter = ko.observableArray<MeasurementMetaData>();
  private testSubjectsFilter = ko.observableArray<DimensionData>();
  private subscriptions: KnockoutSubscription[] = [];
  private testSubjectsDmIds: string[] = [];
  private showIconsFor = ko.observableArray(this.showIconsForChoices);
  // @ts-ignore
  private isAdmin = session.isAdmin();
  // @ts-ignore
  private noReportsPlaceholderText = session.isAdmin()
    ? i18n.t('Press Add to include a new custom report')()
    : i18n.t('No custom reports have been added yet')();

  scheduledVisitsOptions = ko.observableArray<ScheduledVisitData>();
  traitsOptions = ko.observableArray<MeasurementMetaData>();
  sitesOptions = ko.observableArray<DimensionData>();
  trialTraits = ko.observable<SelectedGroupedTrait[]>([]);
  selectedTrialSites = ko.observableArray<sitesApi.SiteData>([]);
  selectedTrialSiteForPlayback = ko.observable<sitesApi.SiteData>();
  playbackIsRunning = ko.observable<boolean>(false);
  isBigPlaybackButtonHidden = ko.observable<boolean>(false);
  selectedTrialStaffForPlayback = ko.observableArray<usersApi.UserData>([]);
  totalNumberOfObservations = ko.observable<number>(0);
  visitsGroupedByFacts = ko.observableArray<{
    id: number;
    name: string;
    takenCount: number;
    totalCount: number;
    dataCollectionStatus: DataCollectionStatus;
  }>([]);
  displaySiteOverview = ko.observable<boolean>(true);
  isPlaybackVisitsContainerVisible = ko.observable<boolean>(true);

  selectedControlDimensions = ko.observable<any>();
  expandOverview = ko.observable(false);
  // The column id on which the user has clicked
  selectedColumnId = ko.observable<number>();
  // The row id on which the user has clicked
  selectedRowId = ko.observable<number>();
  savedPageFilter = ko.observable<SavedPageFilter>(new SavedPageFilter());

  trialSitesOptions = ko.observable<sitesApi.SiteData[]>([]);
  trialControlDimensions = ko.observable<dashboardApi.DimensionItem[][]>([]);
  filteredTrialTraits = ko.observable<SelectedGroupedTrait[]>([]);
  traitsSearchText = ko.observable<string>('');
  isLoadingTrialTraits = ko.observable<boolean>(false);
  isLoadingTrialSites = ko.observable<boolean>(false);
  isLoadingTrialControlDimensions = ko.observable<boolean>(false);
  selectedTraitsForStats = ko.observableArray<SelectedTraitItem>([]);
  selectedTraitForPlots = ko.observable<SelectedTraitItem>();

  selectedTraitsForStatsComputed = ko
    .computed(function () {
      return this.selectedTraitsForStats();
    }, this)
    .extend({ rateLimit: { timeout: 1000, method: 'notifyWhenChangesStop' } });

  selectedTraitForPlotsComputed = ko
    .computed(function () {
      return this.selectedTraitForPlots();
    }, this)
    .extend({ rateLimit: { timeout: 1000, method: 'notifyWhenChangesStop' } });

  selectedSitesComputed = ko
    .computed<sitesApi.SiteData[]>(function () {
      return this.selectedTrialSites();
    }, this)
    .extend({ rateLimit: { timeout: 1000, method: 'notifyWhenChangesStop' } });

  selectedControlDimensionsComputed = ko
    .computed(function () {
      return this.selectedControlDimensions();
    }, this)
    .extend({ rateLimit: { timeout: 1000, method: 'notifyWhenChangesStop' } });

  trialTraitsComputed = ko
    .computed(function () {
      this.trial &&
        getTrialTraitsForStats(this.trial.id(), this.traitsSearchText()).then((traits) => {
          const formattedTraits = this.getFormattedTraits(traits);
          this.filteredTrialTraits(formattedTraits);
        });
      return this.traitsSearchText();
    }, this)
    .extend({ rateLimit: { timeout: 250, method: 'notifyWhenChangesStop' } });
  isSearching = ko.observable(false);

  mapPointsForFactsSize = ko.computed(() => {
    return this.mapPoints().filter((point) => point.geometry.type === 'Point').length;
  });

  deseletAll = () => {
    this.selectedTraitForPlots(null);
    this.selectedTraitsForStats([]);
  };

  dimensionsSearchConfig = {
    getSummaryName: (dimensions: dashboardApi.DimensionItem[]) => {
      return dimensions.map((dimension: dashboardApi.DimensionItem) => dimension.dimension_name).join(', ');
    },
    list: (params: ListRequestParams) => {
      let options = this.trialControlDimensions();
      if (params.name_prefix) {
        options = options.filter((dimension: dashboardApi.DimensionItem[]) => {
          return dimension.some((item: dashboardApi.DimensionItem) =>
            item.dimension_name.toLowerCase().includes(params.name_prefix.toLowerCase())
          );
        });
      }
      return new Promise<dashboardApi.DimensionItem[][]>((resolve, reject) => {
        return resolve(options);
      });
    },
    entity: this.selectedControlDimensions,
  };

  siteSearchConfig = {
    getSummaryName: (site: sitesApi.SiteData) => {
      return translate(site.name_json);
    },
    list: (params: ListRequestParams) => {
      return sitesApi.list({
        trial_id: this.trial.id(),
        search: params.name_prefix,
        disabled: false,
        include_geo_zones: true,
      });
    },
    entity: this.selectedTrialSiteForPlayback,
  };

  overviewFilters: FilterDelegate[] = [
    {
      title: i18n.t('Site')(),
      entities: this.sitesFilter,
      list: (params) => {
        let options = this.sitesOptions();
        if (params.name_prefix) {
          options = options.filter(
            (option) => translate(option.name_json).toLowerCase().indexOf(params.name_prefix) > -1
          );
        }
        return Promise.resolve(options);
      },
    },
    {
      title: i18n.t('Visit')(),
      entities: this.scheduledVisitFilter,
      list: (params) => {
        let options = this.scheduledVisitsOptions();
        if (params.name_prefix) {
          options = options.filter(
            (option) => translate(option.name_json).toLowerCase().indexOf(params.name_prefix) > -1
          );
        }
        return Promise.resolve(options);
      },
    },
    {
      title: i18n.t('Traits')(),
      entities: this.traitsFilter,
      list: (params) => {
        let options = this.traitsOptions();
        if (params.name_prefix) {
          options = options.filter((option) => option.name.toLowerCase().indexOf(params.name_prefix) > -1);
        }
        return Promise.resolve(options);
      },
    },
    {
      title: i18n.t(['test_subjects_title', 'Test subjects'])(),
      entities: this.testSubjectsFilter,
      list: (params) =>
        dimensionsApi.listForDMS(this.testSubjectsDmIds, { trial_id: this.trial.id() }, params),
    },
    {
      title: i18n.t('Show icons')(),
      list: (params) => {
        return Promise.resolve(this.showIconsForChoices);
      },
      entities: this.showIconsFor,
    },
  ];
  overviewTableView = new OverviewTableView(this, false);

  hidePlaybackVisitsContainer = () => {
    this.isPlaybackVisitsContainerVisible(false);
  };

  siteFilter = ko.computed(() => {
    return [
      {
        title: i18n.t('Site')(),
        entities: this.selectedTrialSites,
        list: (params: ListRequestParams) => {
          let options = this.trialSitesOptions();
          if (params.name_prefix) {
            options = options.filter(
              (option) => translate(option.name_json).toLowerCase().indexOf(params.name_prefix) > -1
            );
          }
          return Promise.resolve(options);
        },
      },
    ];
  })();

  siteFilterForPlayback = ko.computed(() => [
    {
      title: i18n.t('Staff')(),
      entities: this.selectedTrialStaffForPlayback,
      list: (params: ListRequestParams) => {
        if (!this.selectedTrialSiteForPlayback()) {
          return Promise.resolve([]);
        }
        return getTrialStaffUsers(this.trial.id(), this.selectedTrialSiteForPlayback().id).then(
          (users) => users
        );
      },
    },
  ])();

  pageFilterOptions = ko.observableArray<{ label: string; action: () => void; disabled?: boolean }>([]);

  showIconsForComments = ko.pureComputed(() =>
    this.showIconsFor()
      .map((item) => item.id)
      .includes('comments')
  );
  showIconsForPhotosAndDocuments = ko.pureComputed(() =>
    this.showIconsFor()
      .map((item) => item.id)
      .includes('photos_and_documents')
  );
  showIconsForChangedData = ko.pureComputed(() =>
    this.showIconsFor()
      .map((item) => item.id)
      .includes('changed_data')
  );

  aiChatPlaceholder = i18n.t('Write your question here')();
  enableAiChatSuggestion = i18n.t('The AI chat is available via an Add-on. Contact support to enable it.')();

  triggerOverviewFullscreen = () => {
    this.expandOverview(true);
  };

  triggerExitOverviewFullscreen = () => {
    this.expandOverview(false);
  };

  hasNextValue = () => {
    return (
      this.goRightUntilConditionIsMet(this.selectedRowId(), this.selectedColumnId(), this.hasValue) !== null
    );
  };

  hasPrevValue = () => {
    return (
      this.goLeftUntilConditionIsMet(this.selectedRowId(), this.selectedColumnId(), this.hasValue) !== null
    );
  };

  hasNextSkipValue = () => {
    return (
      this.showIconsFor().length > 0 &&
      this.goRightUntilConditionIsMet(
        this.selectedRowId(),
        this.selectedColumnId(),
        this.hasValueAndMatchesShowIconFilters
      ) !== null
    );
  };

  hasPrevSkipValue = () => {
    return (
      this.showIconsFor().length > 0 &&
      this.goLeftUntilConditionIsMet(
        this.selectedRowId(),
        this.selectedColumnId(),
        this.hasValueAndMatchesShowIconFilters
      ) !== null
    );
  };

  constructor(params: {
    id: string;
    mainTabTitle: string;
    openOnMap: boolean;
    delegate: TrialFactsScreenDelegate;
    loading: ko.Observable<boolean>;
  }) {
    super();

    this.trialId = params.id;
    this.isObservationGroupTabEnabled(session.tenant()?.observation_group_tab_enabled);
    this.isAnalysisTabEnabled(canViewTrialAnalysis());
    this.loadingExternal = params.loading;

    this.loadingAll = ko.computed(() => this.loading() || this.loadingExternal());
    this.selectedTraitsForStatsComputed.subscribe(function (newTraits) {
      if (this.selectedTypeOfAnalysisTab() === AnalysisTabs.STATS) {
        this.loadAnalysisTabGraph();
      }
    }, this);

    this.selectedZoneCode.subscribe(function (newSelectedZoneCode) {
      if (!newSelectedZoneCode) {
        this.selectedTrialSites(this.trialSitesOptions());
        return;
      }
      const sites = this.trialSitesOptions().filter((site) => {
        return site.geographical_zones[this.currentSiteGeoZoneType()]?.grid_code == newSelectedZoneCode;
      });
      this.selectedTrialSites(sites);
    }, this);

    this.selectedSiteGeoZoneType.subscribe(function (newSelectedSiteGeoZoneType) {
      // change sekselectedZoneCode
      this.selectedZoneCode(null);
    }, this);

    this.selectedTrialSiteForPlayback.subscribe(function (newSite) {
      this.playbackIsEnabled(!!newSite);
      this.sitesMapPositions([]);
      this.isBigPlaybackButtonHidden(false);
      this.isPlaybackVisitsContainerVisible(true);
      if (!newSite) {
        this.mapPoints([]);
      } else {
        this.loadPlaybackData([newSite]);
      }
    }, this);

    this.selectedTrialStaffForPlayback.subscribe((value) => {
      this.isBigPlaybackButtonHidden(false);
      this.isPlaybackVisitsContainerVisible(true);
      if (value.length > 0) {
        this.loadPlaybackData([this.selectedTrialSiteForPlayback()]);
      }
    });

    this.fromDateFilterValue.subscribe((value) => {
      if (this.selectedTrialSiteForPlayback()) {
        this.loadPlaybackData([this.selectedTrialSiteForPlayback()]);
      }
    });

    this.toDateFilterValue.subscribe((value) => {
      if (this.selectedTrialSiteForPlayback()) {
        this.loadPlaybackData([this.selectedTrialSiteForPlayback()]);
      }
    });

    this.mapProgress.subscribe((mapProgressCurrentValue) => {
      if (!this.mapPoints()) {
        return;
      }

      const visiblePoints = this.mapPoints().filter((point, index) => index <= mapProgressCurrentValue - 1);

      const lastRenderedFactId = visiblePoints[visiblePoints.length - 1]?.metaData?.fact_id;
      if (lastRenderedFactId) {
        this.lastRenderedFactScheduledVisitId(
          this.trialLocationsSummary().facts_summary.find((fact) => fact.id == lastRenderedFactId)
            .scheduled_visit_id
        );
      }
      this.currentPlaybackMapPoints([...this.sitesMapPositions(), ...visiblePoints]);
    }, this);

    this.lastRenderedFactScheduledVisitId.subscribe((scheduledVisitId) => {
      // @ts-ignore
      if (scheduledVisitId) {
        document.getElementById(`scheduled-visit-${scheduledVisitId}`)?.scrollIntoView();
      }
    });

    this.selectedTraitForPlotsComputed.subscribe(function (newTrait) {
      if (this.selectedTypeOfAnalysisTab() !== AnalysisTabs.STATS) {
        this.loadAnalysisTabGraph();
      }
    }, this);

    this.selectedSitesComputed.subscribe(function (newTrait) {
      this.loadAnalysisTabGraph();
    }, this);

    this.selectedControlDimensionsComputed.subscribe(function (newDimensions) {
      this.loadAnalysisTabGraph();
    }, this);

    this.selectedTypeOfInteractionGraph.subscribe(function (newType) {
      this.loadInteractionPlot();
    }, this);

    this.selectedXAxisDimensionMetaId.subscribe(function (newDimensionId) {
      this.loadInteractionPlot();
    }, this);

    this.mainTabTitle = params.mainTabTitle;
    this.delegate = params.delegate;

    let trialPromise = trialsApi.retrieve(params.id);
    let datasetsPromise = datasetsApi.list(params.id);
    let unitsPromise = listUnits({});
    this.measurementMetasUnits = {};

    this.selectedTraitsForStats.subscribe((traits) => {
      this.savedPageFilter().value({
        ...this.savedPageFilter().value(),
        selectedTraitsForStats: traits,
      });
    });

    this.selectedTraitForPlots.subscribe((trait) => {
      this.savedPageFilter().value({
        ...this.savedPageFilter().value(),
        selectedTraitForPlots: trait,
      });
    });

    this.selectedTrialSites.subscribe((sites) => {
      this.savedPageFilter().value({
        ...this.savedPageFilter().value(),
        selectedTrialSites: sites,
      });
    });

    let promise = Promise.all([trialPromise, datasetsPromise, unitsPromise]).then(
      ([trialData, datasetsData, unitsData]) => {
        const tenant = session.tenant();
        this.trial = new Trial(null, trialData);
        this.units = unitsData;
        this.scheduledVisitsOptions(this.trial.scheduledVisits());
        this.traitsOptions(this.trial.traits());
        this.sitesOptions(this.trial.sites());

        const needToLimitByFilters = this.trial.measurementsToCollect() > MEASUREMENT_COUNT_LIMIT;
        if (needToLimitByFilters && this.sitesOptions()) {
          this.sitesFilter.push(this.sitesOptions()[0]);
        }
        if (needToLimitByFilters && this.scheduledVisitsOptions()) {
          this.scheduledVisitFilter.push(this.scheduledVisitsOptions()[0]);
        }

        this.isTechnicalScorecardEnabled(this.trial?.tpp() !== null);

        // if there is no tenant, fallback to staff role
        const role = tenant ? tenant.role : 'staff';
        this.canEditTrials = canEditTrial({ id: tenant?.user_id, role: role }, this.trial);
        this.datasetsFacts(datasetsData.map((data) => new DatasetFacts(trialData.id, data, true)));

        const dmIds = new Set<string>();
        for (const ds of datasetsData) {
          for (const dmId of ds.ts_dm_ids) {
            dmIds.add(dmId);
          }
        }
        dmIds.forEach((dmId) => this.testSubjectsDmIds.push(dmId));

        this.subscriptions.push(this.sitesFilter.subscribe(this.onOverviewFilters));
        this.subscriptions.push(this.scheduledVisitFilter.subscribe(this.onOverviewFilters));
        this.subscriptions.push(this.traitsFilter.subscribe(this.onOverviewFilters));
        this.subscriptions.push(this.testSubjectsFilter.subscribe(this.onOverviewFilters));
        this.subscriptions.push(
          this.traitValuesForCompareToControlPlot.subscribe(this.computeSitesGroupedByGeoZones)
        );
        this.subscriptions.push(this.selectedSitesComputed.subscribe(this.computeSitesGroupedByGeoZones));
        this.subscriptions.push(this.selectedSiteGeoZoneType.subscribe(this.computeSitesGroupedByGeoZones));
        this.subscriptions.push(
          this.isZoneDataAvailable.subscribe(() => {
            this.loadCompareToControlPlot();
          })
        );
        this.getTrialTestSubjectDimensions(this.trial.id());
      }
    );

    this.loadedAfter(promise).then(() => {
      const lastSelectedTab = localStorage.getItem(STORAGE_KEYS.TRIAL_DASHBOARD_LAST_VISITED_TAB);
      if (params.openOnMap) {
        this.selectMapTab();
      } else if (lastSelectedTab) {
        const lastSelectedTabInteger = parseInt(lastSelectedTab);

        // If the feature was disabled at some point, we need to reset the tab to the main tab
        if (lastSelectedTabInteger === Tab.CUSTOM_REPORTS && !this.isCustomReportsFeatureEnabled) {
          this.selectMainTab();
        }
        switch (lastSelectedTabInteger) {
          case Tab.MAIN:
            this.selectMainTab();
            break;
          case Tab.MAP:
            this.selectMapTab();
            break;
          case Tab.WEATHER:
            this.selectWeatherTab();
            break;
          case Tab.OVERVIEW:
            this.selectOverviewTab();
            break;
          case Tab.ANALYSIS:
            this.selectAnalysisTab();
            break;
          case Tab.OBS_GROUP:
            this.selectObservationGroupTab();
            break;
          case Tab.CUSTOM_REPORTS:
            this.selectCustomReportsTab();
            break;
          default:
            throw new Error(`Unknown tab ${lastSelectedTabInteger}.`);
        }
      }

      this.selected.subscribe((newTab) => {
        localStorage.setItem(STORAGE_KEYS.TRIAL_DASHBOARD_LAST_VISITED_TAB, newTab.toString());
      }, this);
    });
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
    this.subscriptions = [];
    this.overviewTableView.dispose();
  }

  isMainTabSelected = ko.pureComputed(() => !this.dataset() && this.selected() === Tab.MAIN);
  isMapTabSelected = ko.pureComputed(() => !this.dataset() && this.selected() === Tab.MAP);
  isWeatherTabSelected = ko.pureComputed(() => !this.dataset() && this.selected() === Tab.WEATHER);
  isOverviewTabSelected = ko.pureComputed(() => !this.dataset() && this.selected() === Tab.OVERVIEW);
  isCustomReportsTabSelected = ko.pureComputed(
    () => !this.dataset() && this.selected() === Tab.CUSTOM_REPORTS
  );
  isAnalysisTabSelected = ko.pureComputed(() => !this.dataset() && this.selected() === Tab.ANALYSIS);
  isObservationGroupTabSelected = ko.pureComputed(() => this.selected() === Tab.OBS_GROUP);

  selectedTypeOfAnalysisTab = ko.observable<AnalysisTabs>(AnalysisTabs.SCATTER);

  selectAnalysisSubTab(tab: AnalysisTabs) {
    if (this.selectedTypeOfAnalysisTab() === tab) {
      return;
    }
    this.selectedTypeOfAnalysisTab(tab);
    this.loadAnalysisTabGraph();
    if (tab === AnalysisTabs.COMPARE_TO_CONTROL && this.trialControlDimensions().length === 0) {
      this.isLoadingTrialControlDimensions(true);
      dashboardApi
        .fetchTrialControlDimensions(this.trial.id())
        .then((dimensions: dashboardApi.DimensionItemsResponse) => {
          this.trialControlDimensions(dimensions.data);
          if (dimensions.data?.length > 0) {
            this.selectedControlDimensions(dimensions.data[0]);
          }
        })
        .finally(() => {
          this.isLoadingTrialControlDimensions(false);
        });
    }
  }

  saveSavedPagedFilter = async () => {
    try {
      await confirmDialog(
        i18n.t('Save filters')(),
        i18n.t(
          'The filters are going to be visible to all users of the organization. Are you sure you want to save the filters?'
        )()
      );
    } catch (e) {
      // User rejected the modal
      return;
    }

    if (this.savedPageFilter().id()) {
      await savedPageFilterApi.update(this.savedPageFilter().toData());
    } else {
      await savedPageFilterApi.create(this.savedPageFilter().toData());
    }
    await this.fetchSavedPageFilter();
  };

  deleteSavedPageFilter = async () => {
    try {
      await confirmDialog(
        i18n.t('Clear filters')(),
        i18n.t(
          'This operation will clear the filters for all the users in the organization. Are you sure you want to continue?'
        )()
      );
    } catch (e) {
      // User rejected the modal
    }
    if (this.savedPageFilter().id()) {
      await savedPageFilterApi.remove(this.savedPageFilter().id());
      await this.fetchSavedPageFilter();
    }
  };

  createPageFilterOptions = (savedPageFilter: ko.Observable<SavedPageFilter | undefined>) => {
    return [
      {
        label: i18n.t('Save')(),
        action: this.saveSavedPagedFilter,
      },
      {
        label: i18n.t('Clear')(),
        action: this.deleteSavedPageFilter,
        disabled: !savedPageFilter() || !savedPageFilter().id(),
      },
    ];
  };

  switchToOverviewMapType = () => {
    this.selectedMapTabType(MapTabType.OVERVIEW);
    this.loadFactsMap();
  };
  switchToPlaybackMapType = () => {
    this.selectedMapTabType(MapTabType.PLAYBACK);
    this.isPlaybackVisitsContainerVisible(true);
    this.isLoadingTrialSites(true);
    sitesApi
      .list({ trial_id: this.trial.id(), disabled: false, include_geo_zones: true })
      .then((sites: sitesApi.SiteData[]) => {
        if (sites.length === 0) {
          return;
        }
        this.trialSitesOptions(sites);
        this.selectedTrialSiteForPlayback(sites[0]);
        this.selectedTrialSites(sites);
      })
      .finally(() => {
        this.isLoadingTrialSites(false);
      });
  };
  customReports = ko.observableArray<customReportsApi.CustomReportData>([]);

  fetchReports = async () => {
    const data = await customReportsApi.retrieveCustomReports({ trial: this.trialId });

    this.customReports(data);
  };

  onAddReportButtonClick = () => {
    app.formsStackController
      .push({
        title: i18n.t('Add Custom Report')(),
        name: 'custom-dashboard-edit',
        params: {
          trialId: this.trialId,
          result: new Deferred<{}>(),
        },
      })
      .finally(this.fetchReports);
  };
  changePlayBackState = () => {
    this.displaySiteOverview(false);
    this.isBigPlaybackButtonHidden(true);
    if (this.currentPlaybackMapPoints().length >= this.mapPoints().length) {
      this.mapProgress(0);
      this.currentPlaybackMapPoints([...this.sitesMapPositions()]);
      this.playbackIsRunning(true);
    } else {
      const currentPlayBackState = this.playbackIsRunning();
      this.playbackIsRunning(!currentPlayBackState);
    }
  };

  moveBackwards = () => {
    if (!this.displaySiteOverview() && this.mapProgress() > 0) {
      this.mapProgress(this.mapProgress() - 1);
      this.markersPopUps().pop();
      if (!this.playbackIsRunning() && this.markersPopUps().length) {
        this.markersPopUps().slice(-1)[0]();
      }
    }
  };

  moveForwards = () => {
    if (!this.displaySiteOverview() && this.mapProgress() < this.totalNumberOfObservations()) {
      this.mapProgress(this.mapProgress() + 1);
      if (!this.playbackIsRunning()) {
        this.markersPopUps().slice(-1)[0]();
      }
    }
  };

  skipToStart = () => {
    if (!this.displaySiteOverview()) {
      this.playbackIsRunning(false);
      this.mapProgress(0);
    }
  };

  skipToEnd = () => {
    if (!this.displaySiteOverview()) {
      this.playbackIsRunning(false);
      this.mapProgress(this.totalNumberOfObservations());
    }
  };

  deleteCustomReport = async (id: number) => {
    await customReportsApi.deleteCustomReport(id);
    await this.fetchReports();
  };

  statsValuesLengthPerTrait = (traitIndex: number) => {
    return this.statsResponse().additional_data?.sites_per_trait[traitIndex];
  };

  showTPPTraits = ko.pureComputed(() => !!this.trial?.tpp());

  statsResponse = ko.observable<{
    rows: [][];
    additional_data: {
      sites_per_trait: number[];
      index_of_factors_start: number[];
      stats_type: dashboardApi.TrialStatsType;
    };
  }>(null);

  statsRows = ko.computed(() => {
    if (!this.statsResponse()) {
      return [];
    }
    return this.statsResponse().rows;
  });

  indexesOfFactorsStart = ko.computed(() => {
    if (!this.statsResponse()) {
      return [];
    }
    return this.statsResponse().additional_data?.index_of_factors_start;
  });

  trialStatsType = ko.computed(() => {
    return this.statsResponse()?.additional_data?.stats_type;
  });

  getColorForPValue(value: { value: number }, fieldName: string) {
    if (!fieldName.includes('value')) {
      return 'none';
    }
    return getColorForPValue(value.value);
  }
  getColor = getColor;

  getText(value: any) {
    if (value && typeof value === 'object' && value.hasOwnProperty('value')) {
      return value.value;
    }
    return value;
  }

  selectMainTab = () => {
    this.selected(Tab.MAIN);
    this.dataset(null);
    this.delegate?.onMainTabSelected();
  };

  selectMapTab = () => {
    this.selected(Tab.MAP);
    this.dataset(null);
    this.switchToOverviewMapType();
  };

  loadPlaybackData = (newSites: sitesApi.SiteData[] | null = null) => {
    if (this.selected() === Tab.MAP) {
      this.currentPlaybackMapPoints([]);
      this.playbackIsRunning(false);
      const sitesIds = newSites.map((site) => site.id);
      this.loadFactsMap(sitesIds);
    }
  };

  displayOffSiteObservations = () => {
    this.loadingMap(true);
    this.currentPlaybackMapPoints([]);
    this.currentPlaybackMapPoints([...this.sitesMapPositions(), ...this.mapPoints()]);
    this.loadingMap(false);
  };

  loadFactsMap = (sitesIds?: string[]) => {
    this.mapPoints([]);
    this.mapProgress(0);
    this.trialLocationsSummary(null);
    this.loadingMap(true);
    this.displaySiteOverview(true);
    let objectToPass = {};

    const adjustedToTzFromDate = this.fromDateFilterValue()
      ? adjustDateToTimezoneOffset(this.fromDateFilterValue())
      : null;
    const adjustedToTzToDate = this.toDateFilterValue()
      ? adjustDateToTimezoneOffset(this.toDateFilterValue())
      : null;

    if (this.selectedMapTabType() === MapTabType.PLAYBACK) {
      objectToPass = {
        sitesIds,
        startDate: adjustedToTzFromDate?.toISOString()?.split('T')?.[0] || undefined,
        endDate: adjustedToTzToDate?.toISOString()?.split('T')?.[0] || undefined,
        usersIds: this.selectedTrialStaffForPlayback().map((user) => user.id),
      };
    }
    getTrialFacts(this.trial.id(), objectToPass)
      .then((trialLocationsSummary) => {
        this.loadingMap(false);
        this.trialLocationsSummary(trialLocationsSummary);
        trialLocationsSummary.facts_summary.sort((a, b) => {
          return parseInt(a.timestamp) - parseInt(b.timestamp);
        });
        this.sitesMapPositions([...siteSummaryToGeoJSON(trialLocationsSummary.sites_summary)]);
        // order matters, sites should be displayed first
        this.mapPoints([
          ...siteSummaryToGeoJSON(trialLocationsSummary.sites_summary),
          ...factSummaryToGeoJSON(trialLocationsSummary.facts_summary),
        ]);
        if (!this.playbackIsEnabled()) {
          this.currentPlaybackMapPoints([
            ...siteSummaryToGeoJSON(trialLocationsSummary.sites_summary),
            ...factSummaryToGeoJSON(trialLocationsSummary.facts_summary),
          ]);
          this.offSiteObservationsNumber(null);
        } else {
          const [offSiteObs, onSiteObs] = retrieveOnSiteAndOffSitePoints(
            trialLocationsSummary.sites_summary[0],
            trialLocationsSummary.facts_summary
          );
          this.currentPlaybackMapPoints([
            ...siteSummaryToGeoJSON(trialLocationsSummary.sites_summary),
            ...factSummaryToGeoJSON(onSiteObs),
            ...factSummaryToGeoJSON(offSiteObs),
          ]);
          this.offSiteObservationsNumber(offSiteObs.length);
        }
        this.totalNumberOfObservations(this.mapPoints().length);
      })
      .finally(() => {
        getVisitsForFacts(this.trial.id(), objectToPass).then(
          (
            data: {
              id: number;
              name: string;
              taken_count: number;
              total_count: number;
              data_collection_status: DataCollectionStatus;
            }[]
          ) => {
            this.visitsGroupedByFacts(
              data.map((entry) => ({
                id: entry.id,
                name: entry.name,
                takenCount: entry.taken_count,
                totalCount: entry.total_count,
                dataCollectionStatus: entry.data_collection_status,
              }))
            );
          }
        );
      })
      .catch((e) => {
        this.loadingMap(false);
        throw e;
      });
  };

  selectWeatherTab = () => {
    this.selected(Tab.WEATHER);
    this.dataset(null);

    this.loadingWeather(true);
    weather(this.trial.id())
      .then((charts) => {
        this.loadingWeather(false);
        this.weatherChartsConfig(
          charts.map((data) => ({
            type: data.type,
            scaleType: 'time' as 'time',
            title: data.title,
            yTitle: data.y_title,
            yTitle2: data.y_title2,
            xTitle: data.x_title,
            fontSize: 12,
            labels: data.x_labels,
            datasets: data.data,
            timeUnit: data.time_unit,
            verticalLine: {
              title: i18n.t(['planting_date_title', 'Planting/reference date'])(),
              x: data.planting_date,
            },
          }))
        );
      })
      .catch((e) => {
        this.loadingWeather(false);
        throw e;
      });
  };

  getNextFactIdAndMmId = (): [string, string] => {
    const newCoordinates = this.goRightUntilConditionIsMet(
      this.selectedRowId(),
      this.selectedColumnId(),
      this.hasValue
    );
    if (newCoordinates !== null) {
      this.selectedRowId(newCoordinates[0]);
      this.selectedColumnId(newCoordinates[1]);
    }

    const factId = this.overviewSparseData.idAt(this.selectedRowId(), this.selectedColumnId());
    const measurementMetaId = this.overviewSparseData.mmIdAt(this.selectedColumnId());

    return [factId, measurementMetaId];
  };

  hasValue = (rowId: number, columnId: number) => {
    const value = this.overviewSparseData.valueAt(this.overviewSparseData.values, rowId, columnId);
    const valueType = this.overviewData.value_types[columnId];

    return value !== undefined && value !== null && !DerivedValueTypes.includes(valueType);
  };

  hasValueAndMatchesShowIconFilters = (rowId: number, columnId: number) => {
    if (!this.hasValue(rowId, columnId)) {
      return false;
    }

    if (this.showIconsForChangedData() && !this.doesCellHaveEditHistory(rowId, columnId)) {
      return false;
    }

    if (this.showIconsForComments() && !this.doesCellHaveComment(rowId, columnId)) {
      return false;
    }

    if (this.showIconsForPhotosAndDocuments() && !this.doesCellHavePictureOrDocument(rowId, columnId)) {
      return false;
    }

    return true;
  };

  getPrevFactIdAndMmId = (): [string, string] => {
    const newCoordinates = this.goLeftUntilConditionIsMet(
      this.selectedRowId(),
      this.selectedColumnId(),
      this.hasValue
    );
    if (newCoordinates !== null) {
      this.selectedRowId(newCoordinates[0]);
      this.selectedColumnId(newCoordinates[1]);
    }

    const factId = this.overviewSparseData.idAt(this.selectedRowId(), this.selectedColumnId());
    const measurementMetaId = this.overviewSparseData.mmIdAt(this.selectedColumnId());

    return [factId, measurementMetaId];
  };

  getNextSkipFactIdAndMmId = (): [string, string] => {
    const newCoordinates = this.goRightUntilConditionIsMet(
      this.selectedRowId(),
      this.selectedColumnId(),
      this.hasValueAndMatchesShowIconFilters
    );
    if (newCoordinates !== null) {
      this.selectedRowId(newCoordinates[0]);
      this.selectedColumnId(newCoordinates[1]);
    }

    const factId = this.overviewSparseData.idAt(this.selectedRowId(), this.selectedColumnId());
    const measurementMetaId = this.overviewSparseData.mmIdAt(this.selectedColumnId());

    return [factId, measurementMetaId];
  };

  getPrevSkipFactIdAndMmId = (): [string, string] => {
    const newCoordinates = this.goLeftUntilConditionIsMet(
      this.selectedRowId(),
      this.selectedColumnId(),
      this.hasValueAndMatchesShowIconFilters
    );
    if (newCoordinates !== null) {
      this.selectedRowId(newCoordinates[0]);
      this.selectedColumnId(newCoordinates[1]);
    }

    const factId = this.overviewSparseData.idAt(this.selectedRowId(), this.selectedColumnId());
    const measurementMetaId = this.overviewSparseData.mmIdAt(this.selectedColumnId());

    return [factId, measurementMetaId];
  };

  goRightUntilConditionIsMet = (
    startRowId: number,
    startColumnId: number,
    condition: (arg0: number, arg1: number) => boolean
  ): [number, number] | null => {
    let rowId = startRowId;
    let columnId = startColumnId + 1;
    let rowCount = this.overviewData.rows.length;
    let columnCount = this.overviewData.mm_ids.length;

    if (columnId >= columnCount) {
      columnId = 0;
      rowId += 1;
    }

    if (rowId >= rowCount) {
      return null;
    }

    while (!condition(rowId, columnId)) {
      columnId += 1;

      if (columnId >= columnCount) {
        columnId = 0;
        rowId += 1;
      }

      if (rowId >= rowCount) {
        return null;
      }
    }

    return [rowId, columnId];
  };

  goLeftUntilConditionIsMet = (
    startRowId: number,
    startColumnId: number,
    condition: (arg0: number, arg1: number) => boolean
  ): [number, number] | null => {
    let rowId = startRowId;
    let columnId = startColumnId - 1;

    if (columnId < 0) {
      columnId = this.overviewData.mm_ids.length - 1;
      rowId -= 1;
    }
    if (rowId < 0) {
      return null;
    }

    while (!condition(rowId, columnId)) {
      columnId -= 1;
      if (columnId < 0) {
        columnId = this.overviewData.mm_ids.length - 1;
        rowId -= 1;
      }
      if (rowId < 0) {
        return null;
      }
    }

    return [rowId, columnId];
  };

  buildActionsConfig = () => {
    return {
      getNextFactIdAndMmId: this.getNextFactIdAndMmId,
      getPrevFactIdAndMmId: this.getPrevFactIdAndMmId,
      getNextSkipFactIdAndMmId: this.getNextSkipFactIdAndMmId,
      getPrevSkipFactIdAndMmId: this.getPrevSkipFactIdAndMmId,
    };
  };

  buildActionsDisplayConfig = () => {
    return {
      isNextButtonEnabled: this.hasNextValue,
      isPrevButtonEnabled: this.hasPrevValue,
      isNextSkipButtonEnabled: this.hasNextSkipValue,
      isPrevSkipButtonEnabled: this.hasPrevSkipValue,
    };
  };

  doesCellHaveComment = (rowId: number, columnId: number): boolean => {
    const factId = this.overviewSparseData.idAt(rowId, columnId);
    const measurementMetaId = this.overviewSparseData.mmIdAt(columnId);

    const value = this.overviewSparseData.valueAt(this.overviewSparseData.values, rowId, columnId);
    const commentExtrasForFact = this.overviewData['fact_ids_with_comment_extras'][factId];

    return (
      commentExtrasForFact &&
      (commentExtrasForFact.includes(null) || commentExtrasForFact.includes(parseInt(measurementMetaId))) &&
      value != undefined &&
      value != null
    );
  };
  doesCellHavePictureOrDocument = (rowId: number, columnId: number): boolean => {
    const factId = this.overviewSparseData.idAt(rowId, columnId);
    const measurementMetaId = this.overviewSparseData.mmIdAt(columnId);

    const value = this.overviewSparseData.valueAt(this.overviewSparseData.values, rowId, columnId);

    const pictureOrDocumentExtrasForFact =
      this.overviewData['fact_ids_with_picture_or_document_extras'][factId];

    return (
      pictureOrDocumentExtrasForFact &&
      (pictureOrDocumentExtrasForFact.includes(null) ||
        pictureOrDocumentExtrasForFact.includes(parseInt(measurementMetaId))) &&
      value != undefined &&
      value != null
    );
  };

  doesCellHaveEditHistory = (rowId: number, columnId: number): boolean => {
    const factId = this.overviewSparseData.idAt(rowId, columnId);
    const measurementMetaId = this.overviewSparseData.mmIdAt(columnId);

    const editedMeasurementMetaIds = this.overviewData['fact_ids_with_edited_observations'][factId];
    return editedMeasurementMetaIds && editedMeasurementMetaIds.includes(parseInt(measurementMetaId));
  };

  onObservation = (rowIdx: number, colIdx: number) => {
    if (!this.overviewData || !this.overviewSparseData) {
      return;
    }

    this.selectedColumnId(colIdx);
    this.selectedRowId(rowIdx);

    const factId = this.overviewSparseData.idAt(rowIdx, colIdx);
    const measurementMetaId = this.overviewSparseData.mmIdAt(colIdx);

    if (
      this.overviewData.value_types[colIdx] === 'number_derived' ||
      this.overviewData.value_types[colIdx] === 'date_derived'
    ) {
      return;
    }

    const allowFactEditing =
      (this.trial.state() === TrialState.Active || this.trial.state() === TrialState.Completed) &&
      !session.isReadOnlyAdmin();
    if (!session.tenant().observation_history_enabled && !allowFactEditing) {
      return;
    }

    openFactEditAndHistoryDialog(
      factId,
      measurementMetaId,
      allowFactEditing,
      this.buildActionsConfig(),
      this.buildActionsDisplayConfig()
    )
      .then(() => {
        this.reloadOverview();
      })
      .catch(() => this.reloadOverview());
  };

  selectOverviewTab = () => {
    this.selected(Tab.OVERVIEW);
    this.dataset(null);
    this.reloadOverview();
  };

  selectCustomReportsTab = () => {
    this.selected(Tab.CUSTOM_REPORTS);
    this.dataset(null);

    this.fetchReports();
  };

  getFormattedTraits = (traits: TraitItem[]): SelectedGroupedTrait[] => {
    const traitsFinalResult = [];
    for (const trait of traits) {
      if (trait.scheduled_visits.length > 0) {
        for (const scheduledVisit of trait.scheduled_visits) {
          const typeOfVisitGrouping = ko.observable(VisitGroupingType.FIRST_VISIT);
          typeOfVisitGrouping.subscribe((value) => {
            this.loadAnalysisTabGraph();
          });
          const traitCopy: any = { ...trait };
          traitCopy.scheduled_visit = { ...scheduledVisit, typeOfVisitGrouping };
          traitCopy.name_with_unit = trait.unit ? `${trait.name} (${trait.unit})` : trait.name;
          traitsFinalResult.push(traitCopy);
        }
      } else {
        // for case when trait has no scheduled visits
        const typeOfVisitGrouping = ko.observable(VisitGroupingType.FIRST_VISIT);
        typeOfVisitGrouping.subscribe((value) => {
          this.loadAnalysisTabGraph();
        });
        const traitCopy: any = { ...trait };
        traitCopy.scheduled_visit = { id: null, name: '-', typeOfVisitGrouping };
        traitCopy.name_with_unit = trait.unit ? `${trait.name} (${trait.unit})` : trait.name;
        traitsFinalResult.push(traitCopy);
      }
    }
    const groupedTraits = groupBy(traitsFinalResult, 'scheduled_visit.id');
    const finalResult: SelectedGroupedTrait[] = [];
    for (const [, traits] of Object.entries(groupedTraits)) {
      finalResult.push({
        scheduled_visit: traits[0].scheduled_visit,
        traits: traits,
      });
    }
    return finalResult;
  };
  selectAnalysisTab = () => {
    this.selected(Tab.ANALYSIS);
    this.fetchSavedPageFilter();
    this.dataset(null);
    if (this.trialTraits().length === 0) {
      this.isLoadingTrialTraits(true);
      getTrialTraitsForStats(this.trial.id()).then((traits) => {
        this.isLoadingTrialTraits(false);
        const traitsFinalResult = this.getFormattedTraits(traits);

        this.trialTraits(traitsFinalResult);
        this.filteredTrialTraits(traitsFinalResult);
      });
    }

    if (this.trialSitesOptions().length === 0) {
      this.isLoadingTrialSites(true);
      sitesApi
        .list({ trial_id: this.trial.id(), disabled: false, include_geo_zones: true })
        .then((sites: sitesApi.SiteData[]) => {
          this.trialSitesOptions(sites);
          this.selectedTrialSites(sites);
        })
        .finally(() => {
          this.isLoadingTrialSites(false);
        });
    }
  };

  selectObservationGroupTab() {
    this.selected(Tab.OBS_GROUP);
    this.select(this.datasetsFacts()[0]);
  }

  fetchSavedPageFilter = async () => {
    const data = await savedPageFilterApi.list({
      page: savedPageFilterApi.Page.TRIAL_ANALYSIS,
      trial: Number.parseInt(this.trialId),
    });
    // For this page, there can be only one saved filter which is shared across all users of the trial
    switch (data.length) {
      case 1:
        this.savedPageFilter(new SavedPageFilter(data[0]));
        if (this.savedPageFilter().value().selectedTraitsForStats) {
          this.selectedTraitsForStats(this.savedPageFilter().value().selectedTraitsForStats);
        }
        if (this.savedPageFilter().value().selectedTraitForPlots) {
          this.selectedTraitForPlots(this.savedPageFilter().value().selectedTraitForPlots);
        }
        if (this.savedPageFilter().value().selectedTrialSites) {
          this.selectedTrialSites(this.savedPageFilter().value().selectedTrialSites);
        }
        break;
      case 0:
        this.savedPageFilter(new SavedPageFilter());
        this.savedPageFilter().trialId(Number.parseInt(this.trialId));
        this.savedPageFilter().page(savedPageFilterApi.Page.TRIAL_ANALYSIS);
        break;
      default:
        break;
    }
  };

  loadStatistics = async () => {
    const bodyOfRequest = this.selectedTraitsForStats().map((trait) => {
      return {
        trait_id: trait.pk,
        scheduled_visit_id: trait.scheduled_visit.id,
        visit_grouping_type: trait.scheduled_visit?.typeOfVisitGrouping(),
      };
    });
    const rows = await fetchTechnicalScorecard(
      this.trial.id(),
      {
        site_ids: this.selectedSitesComputed().map((s: any) => s.id),
      },
      bodyOfRequest
    );
    this.statsResponse(rows);
  };

  togglePlotTrait = (data: SelectedTraitItem) => {
    if (!data) {
      return;
    }
    if (
      this.selectedTraitForPlots()?.pk === data.pk &&
      this.selectedTraitForPlots()?.scheduled_visit.id === data.scheduled_visit.id
    ) {
      this.selectedTraitForPlots(null);
    } else {
      this.selectedTraitForPlots(data);
    }
  };
  toggleStatsTrait = (data: SelectedTraitItem) => {
    if (!data) {
      return;
    }
    if (
      this.selectedTraitsForStats().find(
        (trait) => trait.pk === data.pk && trait.scheduled_visit.id === data.scheduled_visit.id
      )
    ) {
      const newTraits = this.selectedTraitsForStats().filter(
        (trait) => trait.pk !== data.pk || trait.scheduled_visit.id !== data.scheduled_visit.id
      );
      this.selectedTraitsForStats(newTraits);
    } else {
      this.selectedTraitsForStats([...this.selectedTraitsForStats(), data]);
    }
  };
  isTraitSelectedForPlot = (data: SelectedTraitItem) => {
    return (
      this.selectedTraitForPlots()?.pk === data.pk &&
      this.selectedTraitForPlots()?.scheduled_visit.id === data.scheduled_visit.id
    );
  };

  isTraitSelectedForStats = (data: any) => {
    return this.selectedTraitsForStats().find(
      (trait: SelectedTraitItem) =>
        trait.pk === data.pk && trait.scheduled_visit.id === data.scheduled_visit.id
    );
  };
  private onOverviewFilters = () => {
    this.reloadOverview();
  };

  private async reloadOverview() {
    if (this.loadingOverview() === 'loaded') {
      this.loadingOverview('update');
    }
    this.overviewTableView.updateLoading(true);
    try {
      this.overviewData = await dashboardApi.fetchOverview(this.trial.id(), {
        site_ids: deflateList(this.sitesFilter),
        sv_ids: deflateList(this.scheduledVisitFilter),
        ts_ids: deflateList(this.testSubjectsFilter),
        trait_ids: deflateList(this.traitsFilter),
      });
      this.overviewSparseData = new OverviewSparseData(this.overviewData);
      this.overviewTableView.updateData(
        this.overviewData,
        this.overviewSparseData,
        this.canEditTrials,
        null
      );
    } finally {
      this.overviewTableView.updateLoading(false);
      this.loadingOverview('loaded');
    }
  }

  isSelected = (dataset: DatasetFacts): boolean => {
    return this.dataset() === dataset;
  };

  select = (dataset: DatasetFacts) => {
    dataset.reloadIfNecessary();
    this.dataset(dataset);
  };

  exportTechnicalScorecard = async () => {
    this.exportingTechnicalScorecard(true);
    try {
      const bodyOfRequest = this.selectedTraitsForStats().map((trait) => {
        return {
          trait_id: trait.pk,
          scheduled_visit_id: trait.scheduled_visit.id,
          visit_grouping_type: trait.scheduled_visit?.typeOfVisitGrouping(),
        };
      });
      let data = await exportTechnicalScorecardFile(
        this.trial.id(),
        {
          site_ids: this.selectedSitesComputed().map((s: sitesApi.SiteData) => s.id),
        },
        bodyOfRequest
      );
      downloadBlob(data, 'stats.xlsx');
    } finally {
      this.exportingTechnicalScorecard(false);
    }
  };

  exportWeather = async () => {
    this.exportingWeather(true);

    try {
      let data = await exportWeather(this.trial.id());
      downloadBlob(data, 'weather.xlsx');
    } finally {
      this.exportingWeather(false);
    }
  };

  openMap = (value: {}) => {
    app.formsStackController.push({
      title: i18n.t('Map')(),
      name: 'map',
      isBig: true,
      params: {
        value: [value],
        result: new Deferred<{}>(),
      },
    });
  };

  openPictures = (urls: string[]) => {
    viewImages(urls);
  };

  openVideo = (url: string) => {
    viewVideo(url);
  };

  async editDerivedTrait(scheduledVisitId: string, datasetId: string, mmId: string) {
    if (this.loadingOverview() !== 'loaded') {
      return;
    }

    await openEditDerivedTrait({
      trial: this.trial,
      scheduledVisitId,
      datasetId,
      id: mmId,
    });

    for (let dsFacts of this.datasetsFacts()) {
      if (dsFacts.id === datasetId) {
        dsFacts.markReload();
      }
    }

    if (this.isOverviewTabSelected()) {
      this.reloadOverview();
    }
  }

  onZoneClick = (zoneData: any) => {
    if (!APP_CONFIG.SHOW_GEOGRAPHIC_ZONES) {
      return;
    }
    const zoneType =
      this.selectedSiteGeoZoneType() === SiteGeoZoneTypeToDisplay.GYGA_CLIMATE_ZONE
        ? sitesApi.SiteGeoZoneType.GYGA_CLIMATE_ZONE
        : sitesApi.SiteGeoZoneType.GYGA_TED_ZONE;
    selectLocationZonePopup(zoneData.zoneId, zoneType, zoneData.sites, this.zonesColored());
  };
  traitValuesForInteractionPlot = ko.observable<TraitDataInteractionPlot>();
  currentScatterCharts = ko.observableArray<any>([]);
  currentInteractionCharts = ko.observable<ChartConfiguration[]>([]);
  currentBarChart = ko.observable<any>(null);
  currentCompareToControlCharts = ko.observableArray<any>([]);
  traitValuesForScatterPlot = ko.observable<TraitDataResponse>();
  traitValuesForCompareToControlPlot = ko.observable<TraitCompareToControlResponse>();

  selectedSiteGeoZoneType = ko.observable<SiteGeoZoneTypeToDisplay>(
    SiteGeoZoneTypeToDisplay.GYGA_CLIMATE_ZONE
  );
  selectedZoneCode = ko.observable<number | null>(null);
  siteGeoZones = ko.observableArray<SiteGeoZoneTypeToDisplay>(Object.values(SiteGeoZoneTypeToDisplay));
  sitesGroupedByGeoZones = ko.observable<SitesGroupedByGeoZones>({});

  visitGroupingTypes = ko.observableArray<{ value: string; label: string }>([
    {
      value: VisitGroupingType.FIRST_VISIT,
      label: i18n.t('First visit')(),
    },
    {
      value: VisitGroupingType.ALL_VISITS,
      label: i18n.t('All visits')(),
    },
  ]);

  computeSitesGroupedByGeoZones = () => {
    const selectedSites = this.selectedSitesComputed();
    if (!this.traitValuesForCompareToControlPlot() || !this.traitValuesForCompareToControlPlot().values) {
      this.sitesGroupedByGeoZones({});
      return;
    }

    if (!selectedSites || selectedSites.length === 0) {
      return;
    }
    const siteGroupedByZone: SitesGroupedByGeoZones = {};
    const addedSitesIds = new Set();
    this.traitValuesForCompareToControlPlot().values.forEach((entry) => {
      const siteWithExtraData = selectedSites.find((s: sitesApi.SiteData) => s.id === entry.site_id);
      let geoZone: { grid_code: string | number; id: number | null };
      if (this.selectedSiteGeoZoneType() === SiteGeoZoneTypeToDisplay.NONE) {
        geoZone = {
          grid_code: 'Sites',
          id: null as null,
        };
      } else {
        geoZone = siteWithExtraData?.geographical_zones?.[this.currentSiteGeoZoneType()] || {
          grid_code: 'No data',
          id: null as null,
        };
      }
      if (!siteGroupedByZone[geoZone.grid_code]) {
        siteGroupedByZone[geoZone.grid_code] = {
          sites: [{ ...entry, gps_location: siteWithExtraData?.gps_location }],
          zoneId: geoZone.id,
        };
        addedSitesIds.add(entry.site_id);
      } else if (!addedSitesIds.has(entry.site_id)) {
        siteGroupedByZone[geoZone.grid_code].sites.push({
          ...entry,
          gps_location: siteWithExtraData?.gps_location,
        });
        addedSitesIds.add(entry.site_id);
      }
    });
    this.sitesGroupedByGeoZones(siteGroupedByZone);
  };

  allZonesObjects = ko.computed<sitesApi.GeoZone[]>(() => {
    const zones: sitesApi.GeoZone[] = [];
    this.trialSitesOptions().forEach((site) => {
      if (this.selectedSiteGeoZoneType() === SiteGeoZoneTypeToDisplay.NONE) {
        return;
      }
      const zone = site.geographical_zones[this.currentSiteGeoZoneType()];
      if (zone) {
        zones.push(zone);
      }
    });
    return zones.filter((zone, index, self) => self.findIndex((t) => t.id === zone.id) === index);
  });

  allZoneCodes = ko.computed<number[]>(() => {
    return this.allZonesObjects().map((zone) => zone.grid_code);
  });

  zonesColorsByZoneGridCode = ko.computed<{ [id: number]: string }>(() => {
    const siteGroupedByZone: SitesGroupedByGeoZones = {};
    this.trialSitesOptions().forEach((site) => {
      const zone = site.geographical_zones[this.currentSiteGeoZoneType()];
      if (zone) {
        siteGroupedByZone[zone.grid_code] = {
          sites: [],
          zoneId: zone.id,
        };

        // add sites to zone
        siteGroupedByZone[zone.grid_code].sites.push({
          ...site,
          gps_location: site.gps_location,
        });
      }
    });

    const zonesColors: { [id: number]: string } = {};
    Object.keys(siteGroupedByZone).forEach((zoneGridCode: any, index: number) => {
      zonesColors[zoneGridCode] = getColor(index);
    });
    return zonesColors;
  });

  zonesColored = ko.pureComputed<sitesApi.GeoZoneColored[]>(() => {
    return this.allZonesObjects().map((zone) => {
      return {
        ...zone,
        color: this.zonesColorsByZoneGridCode()[zone.grid_code],
      };
    });
  });

  siteColorsById = ko.computed(() => {
    const siteColors: { [id: string]: string } = {};
    this.selectedSitesComputed().forEach((site: sitesApi.SiteData) => {
      const siteZone = site.geographical_zones[this.currentSiteGeoZoneType()];
      if (siteZone) {
        siteColors[site.id] = this.zonesColorsByZoneGridCode()[siteZone.grid_code];
      }
    });
    return siteColors;
  });

  currentSiteGeoZoneType = ko.computed<sitesApi.SiteGeoZoneType | null>(() => {
    if (this.selectedSiteGeoZoneType() === SiteGeoZoneTypeToDisplay.GYGA_CLIMATE_ZONE) {
      return sitesApi.SiteGeoZoneType.GYGA_CLIMATE_ZONE;
    } else if (this.selectedSiteGeoZoneType() === SiteGeoZoneTypeToDisplay.GYGA_TED_ZONE) {
      return sitesApi.SiteGeoZoneType.GYGA_TED_ZONE;
    }
    return null;
  });

  isZoneDataAvailable = ko.computed(() => {
    return (
      APP_CONFIG.SHOW_GEOGRAPHIC_ZONES &&
      Object.keys(this.sitesGroupedByGeoZones()).length > 0 &&
      Object.keys(this.sitesGroupedByGeoZones())[0] !== 'No data' &&
      this.selectedSiteGeoZoneType() !== SiteGeoZoneTypeToDisplay.NONE
    );
  });

  // collection to map site id with alias, e.g. site 1 -> S1, site 2 -> S2
  compareToControlPlotSiteAliases = ko.computed<{ [id: string]: { site_alias: string; site_name: string } }>(
    () => {
      if (!this.traitValuesForCompareToControlPlot() || !this.traitValuesForCompareToControlPlot().values) {
        return {};
      }
      const siteAliases: { [id: string]: any } = {};
      this.traitValuesForCompareToControlPlot().values.forEach((entry) => {
        if (siteAliases[entry.site_id]) {
          return;
        }
        siteAliases[entry.site_id] = entry;
      });
      return siteAliases;
    }
  );

  loadAnalysisTabGraph = async () => {
    const traitId = this.selectedTraitForPlots()?.pk;
    const visitId = this.selectedTraitForPlots()?.scheduled_visit.id;
    const typeOfVisitGrouping = this.selectedTraitForPlots()?.scheduled_visit?.typeOfVisitGrouping?.();
    const selectedSitesIds = this.selectedSitesComputed().map((s: any) => s.id);
    const argumentsToPassToPlotEndpoint = {
      trait_id: traitId,
      site_ids: selectedSitesIds,
      scheduled_visit_id: visitId,
      visit_grouping_type: typeOfVisitGrouping,
    };
    if (selectedSitesIds.length === 0) {
      return;
    }
    this.isLoadingData(true);
    switch (this.selectedTypeOfAnalysisTab()) {
      case AnalysisTabs.SCATTER: {
        this.currentScatterCharts([]);
        this.traitValuesForScatterPlot(null);
        traitId &&
          (await dashboardApi
            .fetchTraitPlots(this.trial.id(), argumentsToPassToPlotEndpoint)
            .then((plots) => {
              this.traitValuesForScatterPlot(plots);
              this.loadScatterPlot();
            }));
        this.isLoadingData(false);
        break;
      }
      case AnalysisTabs.STATS: {
        await this.loadStatistics();
        break;
      }
      case AnalysisTabs.INTERACTION: {
        this.currentInteractionCharts([]);
        this.traitValuesForInteractionPlot(null);
        traitId &&
          (await dashboardApi
            .fetchTraitInteractionPlots(this.trial.id(), argumentsToPassToPlotEndpoint)
            .then((plots) => {
              this.traitValuesForInteractionPlot(plots);
              this.loadInteractionPlot();
            }));
        break;
      }
      case AnalysisTabs.BAR: {
        this.currentBarChart(null);
        this.traitValuesForInteractionPlot(null);
        traitId &&
          (await dashboardApi
            .fetchTraitInteractionPlots(this.trial.id(), argumentsToPassToPlotEndpoint)
            .then((plots) => {
              this.traitValuesForInteractionPlot(plots);
              this.loadBarPlot();
            }));
        break;
      }
      case AnalysisTabs.COMPARE_TO_CONTROL: {
        this.currentCompareToControlCharts([]);
        this.traitValuesForCompareToControlPlot(null);
        if (this.selectedControlDimensions()?.length > 0 && traitId) {
          await dashboardApi
            .fetchTraitCompareToControlPlots(this.trial.id(), {
              ...argumentsToPassToPlotEndpoint,
              control_dimension_ids: this.selectedControlDimensions().map(
                (dim: dashboardApi.DimensionItem) => dim.dimension_id
              ),
            })
            .then((data) => {
              this.traitValuesForCompareToControlPlot(data);
              this.loadCompareToControlPlot();
            });
        }
        break;
      }
    }
    this.isLoadingData(false);
  };

  downloadChart(title: string, canvasID: string) {
    let canvas = <HTMLCanvasElement>document.getElementById(canvasID);
    let fileName = title + '.png';
    if ((canvas as any).msToBlob) {
      downloadBlob((canvas as any).msToBlob(), fileName);
    } else {
      canvas.toBlob((blob) => {
        downloadBlob(blob, fileName);
      });
    }
  }

  createAxisLabelFromDimesions(dimensions: any[]) {
    return dimensions
      .map((dim: any) => {
        if (dim.control) {
          return `${dim.name}*`;
        }
        return dim.name;
      })
      .join(' ');
  }

  loadScatterPlot = (): void => {
    const selectedTraitValues: TraitDataResponse = this.traitValuesForScatterPlot();
    if (!selectedTraitValues || selectedTraitValues?.values?.length === 0) {
      return null;
    }

    const allSites = new Set(this.traitValuesForScatterPlot().values.map((data) => data.site_id));

    allSites.forEach((siteId) => {
      let siteData: TraitForPlotEntity[] = selectedTraitValues.values.filter(
        (entry) => entry.site_id === siteId
      );
      const currentSiteName = siteData.filter((s) => s.site_id === siteId)[0].site_name;
      const xAxisLabels: string[] = Array.from(
        new Set(siteData.map((entry) => this.createAxisLabelFromDimesions(entry.dimensions)))
      );
      let allRepetitions = siteData
        .map((entry) => {
          return {
            repetitionId: entry.repetition_id,
            repetitionName: entry.repetition_name,
          };
        })
        // sort by repetition name
        .sort((a, b) => {
          if (a.repetitionName < b.repetitionName) {
            return -1;
          }
          if (a.repetitionName > b.repetitionName) {
            return 1;
          }
          return 0;
        })
        .map((entry) => entry.repetitionId);
      allRepetitions = Array.from(new Set(allRepetitions));
      const repetitionShapes: PointStyle[] = ['circle', 'triangle', 'crossRot', 'rect', 'rectRot', 'cross'];
      const repetitionShapesMap = new Map<string, PointStyle>();
      if (allRepetitions.length <= repetitionShapes.length) {
        allRepetitions.forEach((repetitionId, index) => {
          repetitionShapesMap.set(repetitionId, repetitionShapes[index]);
        });
      }

      // in case there is no replications repetitionId=null
      const datasets: any[] = allRepetitions.map((repetitionId: string | null, index: number) => {
        const xyValues: any[] = [];
        siteData
          .filter((entry) => entry.repetition_id === repetitionId)
          .forEach((entry) => {
            xyValues.push({ x: this.createAxisLabelFromDimesions(entry.dimensions), y: entry.value });
          });

        const dataset: ChartDataset = {
          pointRadius: 15,
          data: xyValues,
          borderColor: getColor(index),
          label: 'Data',
        };
        if (repetitionShapesMap.size > 0) {
          dataset.pointStyle = repetitionShapesMap.get(repetitionId);
        }

        if (repetitionId) {
          dataset.label = siteData.filter((entry) => entry.repetition_id == repetitionId)[0].repetition_name;
        }
        return dataset;
      });
      const chartTitle = `${selectedTraitValues.trait_name} ${i18n.t(
        'on site'
      )()} ${currentSiteName} ${i18n.t('for all of replications')()}`;

      const config: ChartConfiguration = {
        type: 'scatter',
        data: {
          datasets: datasets,
        },
        options: {
          plugins: {
            title: {
              text: chartTitle,
              display: true,
            },
            legend: {
              position: 'right',
              labels: {
                usePointStyle: true,
              },
            },
            tooltip: {
              callbacks: {
                label: (ttItem: TooltipItem<any>) => {
                  return `${ttItem.dataset.label}: ${ttItem.formattedValue}`;
                },
              },
            },
          },
          scales: {
            x: {
              type: 'category',
              //@ts-ignore
              labels: xAxisLabels,
              offset: true,
              title: {
                display: true,
                font: { size: 15 },
                text: `${selectedTraitValues.values[0].dimensions
                  .map((dim: any) => dim.dimension_meta_name)
                  .join(', ')}`,
              },
            },
            y: {
              title: {
                display: true,
                font: { size: 15 },
                text: `${selectedTraitValues.trait_name}`,
              },
            },
          },
        },
      };
      if (siteData.length > 0) {
        this.currentScatterCharts.push(config);
      }
    });
  };

  selectedTypeOfInteractionGraph = ko.observable<InteractionGraphType>(
    InteractionGraphType.SITES_INTERACTION
  );
  interactionGraphTypes = ko.observableArray<InteractionGraphType>(Object.values(InteractionGraphType));

  // for a trial that has multiple test subject dimensions, e.g. Crop Variety, Chemical, Fertilizer, etc.
  trialTestSubjectDimensionMetas = ko.observableArray<TestSubjectDimension>([]);
  selectedXAxisDimensionMetaId = ko.observable<number>();
  getTrialTestSubjectDimensions(trialId: string) {
    getTrialTestSubjectDimensionMetas(trialId).then((dimension_metas) => {
      this.trialTestSubjectDimensionMetas(dimension_metas);
      if (!this.selectedXAxisDimensionMetaId() && dimension_metas.length > 0) {
        this.selectedXAxisDimensionMetaId(dimension_metas[0].id);
      }
    });
  }

  getXAxisLabels(interactionType: InteractionGraphType, selectedTraitValues: TraitDataInteractionPlot) {
    if (interactionType === InteractionGraphType.SITES_INTERACTION) {
      return Array.from(new Set(selectedTraitValues.values.map((entry) => entry.site_name)));
    }
    const dimensionIndex = selectedTraitValues.trial_test_subject_dimension_metas.findIndex(
      (dim) => dim.id == this.selectedXAxisDimensionMetaId()
    );
    return Array.from(
      new Set(
        selectedTraitValues.values.map((entry) => {
          return entry.dimensions[dimensionIndex].control
            ? `${entry.dimensions[dimensionIndex].name}*`
            : entry.dimensions[dimensionIndex].name;
        })
      )
    );
  }

  getInteractionChartBetweenSites() {
    const selectedTraitValues: TraitDataInteractionPlot = this.traitValuesForInteractionPlot();
    if (!selectedTraitValues || selectedTraitValues?.values?.length === 0) {
      return null;
    }
    const chartTitle = i18n.t('Interaction')();
    const uniqueSites = selectedTraitValues.values
      .map((entry) => {
        return { site_id: entry.site_id, site_name: entry.site_name };
      })
      .filter((v, i, a) => a.findIndex((t) => t.site_id === v.site_id) === i)
      .sort((a, b) => a.site_name.localeCompare(b.site_name));
    const xAxisLabels: string[] = uniqueSites.map((site) => site.site_name);
    const uniqueSitesIds = uniqueSites.map((site) => site.site_id);
    const legendDimensions = [...selectedTraitValues.trial_test_subject_dimension_metas];
    const datasets = this.getDatasetsSitesAsXAxis();
    const xAxisLabelString = selectedTraitValues.site_dimension_name;
    const yAxisLabelString = `${selectedTraitValues.trait_name} ${i18n.t(['mean_lowercase', 'mean'])()}`;

    const lineAnnotations = this.getZoneLineAnnotationsForChart(uniqueSitesIds);

    const config = this.getInteractionGraphDataset(
      datasets,
      chartTitle,
      xAxisLabelString,
      yAxisLabelString,
      legendDimensions,
      xAxisLabels
    );
    config.options.plugins.annotation = {
      annotations: lineAnnotations,
    };
    return config;
  }

  private getZoneLineAnnotationsForChart(uniqueSitesIds: string[]) {
    const lineAnnotations = {};
    uniqueSitesIds
      .map((siteId, index) => {
        return this.getZoneAnnotationLinesForXAxis(siteId, index);
      })
      .forEach((line) => {
        Object.assign(lineAnnotations, line);
      });
    return lineAnnotations;
  }

  private getZoneAnnotationLinesForXAxis = (siteId: string, index: number) => {
    if (!this.getSiteZoneCode(siteId)) {
      return;
    }

    const lineColor = this.getColorForSite(siteId);
    return {
      [`line-${index}`]: {
        type: 'line',
        yMin: 0,
        yMax: 0,
        xMin: index - 0.5,
        xMax: index + 0.5,
        borderColor: lineColor,
        borderWidth: 15,
        label: {
          display: true,
          backgroundColor: lineColor,
          borderRadius: 0,
          color: 'white',
          content: `${i18n.t('Zone')()}: ${this.getSiteZoneCode(siteId)}`,
        },
      },
    };
  };

  getInteractionChartsBetweenDimensions() {
    const selectedTraitValues: TraitDataInteractionPlot = this.traitValuesForInteractionPlot();
    if (!selectedTraitValues || selectedTraitValues?.values?.length === 0) {
      return null;
    }

    const xAxisLabels: string[] = this.getXAxisLabels(
      this.selectedTypeOfInteractionGraph(),
      selectedTraitValues
    );
    const xAxisDimensionIndex = selectedTraitValues.trial_test_subject_dimension_metas.findIndex(
      (dim) => dim.id == this.selectedXAxisDimensionMetaId()
    );
    const legendDimensions = [...selectedTraitValues.trial_test_subject_dimension_metas];
    legendDimensions.splice(xAxisDimensionIndex, 1);

    const charts: ChartConfiguration[] = [];
    this.trialSitesOptions().forEach((site) => {
      const chartTitle = `${i18n.t('Interaction on site')()} ${translate(site.name_json)}`;
      const datasets = this.getDatasetsOneDimensionAsXAxis(site.id);
      const xAxistLabel = `${
        selectedTraitValues.trial_test_subject_dimension_metas.find(
          (dim) => dim.id == this.selectedXAxisDimensionMetaId()
        )?.name
      }`;
      const yAxistLabel = `${selectedTraitValues.trait_name} ${i18n.t(['mean_lowercase', 'mean'])()}`;
      const config: ChartConfiguration = this.getInteractionGraphDataset(
        datasets,
        chartTitle,
        xAxistLabel,
        yAxistLabel,
        legendDimensions,
        xAxisLabels
      );
      if (datasets.length > 0) {
        charts.push(config);
      }
    });
    return charts;
  }

  getInteractionGraphDataset(
    datasets: ChartDataset[],
    chartTitle: string,
    xAxisLabelString: string,
    yAxisLabelString: string,
    legendDimensions: any[],
    xAxisLabels: string[]
  ) {
    const config: ChartConfiguration = {
      type: 'line',
      data: {
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            ...defaultTitle,
            text: chartTitle,
          },
          legend: {
            position: 'right',
            // workaround to show legend title
            onClick: function (e: ChartEvent, legendItem: LegendItem, legend: any) {
              if (legendItem.datasetIndex === -1) {
                e.native.stopPropagation();
              } else {
                // default behavior
                Chart.defaults.plugins.legend?.onClick.call(this, e, legendItem, legend);
              }
            },
            labels: {
              generateLabels: function (chart: any) {
                const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                const text = legendDimensions.map((dim) => dim.name).join(' x ');
                let title: LegendItem = {
                  text: text,
                  strokeStyle: 'transparent',
                  fillStyle: 'transparent',
                  lineWidth: 0,
                  datasetIndex: -1,
                  // onclick nothing
                };
                return [title, ...labels];
              },
            },
          },
        },
        scales: {
          x: {
            type: 'category',
            //@ts-ignore
            labels: xAxisLabels,
            offset: true,
            title: {
              display: true,
              font: { size: 15 },
              text: xAxisLabelString,
            },
          },
          y: {
            title: {
              display: true,
              font: { size: 15 },
              text: yAxisLabelString,
            },
          },
        },
      },
    };
    return config;
  }

  loadInteractionPlot = (): void => {
    let charts: ChartConfiguration[] = [];
    if (this.selectedTypeOfInteractionGraph() === InteractionGraphType.FACTORS_INTERACTION) {
      charts = this.getInteractionChartsBetweenDimensions();
    } else {
      const config = this.getInteractionChartBetweenSites();
      if (config) {
        charts = [config];
      }
    }

    this.currentInteractionCharts(charts);
  };

  loadBarPlot = (): void => {
    this.currentBarChart(null);
    const selectedTraitValues = this.traitValuesForInteractionPlot();
    if (!selectedTraitValues || !selectedTraitValues?.values || selectedTraitValues?.values?.length === 0) {
      return;
    }
    const datasets = this.getDatasetsSitesAsXAxis(true);
    const uniqueSites = selectedTraitValues.values
      .map((entry) => {
        return { site_id: entry.site_id, site_name: entry.site_name };
      })
      .filter((v, i, a) => a.findIndex((t) => t.site_id === v.site_id) === i)
      .sort((a, b) => a.site_name.localeCompare(b.site_name));
    const xAxisLabels: string[] = uniqueSites.map((site) => site.site_name);
    const uniqueSitesIds = uniqueSites.map((site) => site.site_id);
    const chartTitle = `${selectedTraitValues.trait_name}`;
    // if selectedTraitValues.tpp_target is bigger than max value of selectedTraitValues.values then use selectedTraitValues.tpp_target + 10% as max value
    const maxDatasetValue = Math.max(...selectedTraitValues.values.map((entry) => entry.value));
    let maxYValue = maxDatasetValue;
    if (selectedTraitValues.tpp_target) {
      maxYValue = Math.max(maxDatasetValue, parseFloat(selectedTraitValues.tpp_target));
    }
    const maxYTickValue = Math.ceil(maxYValue * 1.1);
    const lineAnnotations = this.getZoneLineAnnotationsForChart(uniqueSitesIds);

    const config: ChartConfiguration = {
      type: 'bar',
      plugins: [ChartDataLabels],
      data: {
        datasets: [...datasets],
      },
      options: {
        plugins: {
          legend: {
            position: 'right',
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            font: {
              size: 10,
            },
            rotation: 270,
            formatter: function (value: any, context: DatalabelsContext) {
              if (!value?.lsdLabels || value?.lsdLabels?.length === 0) {
                return null;
              }
              return truncate(value.lsdLabels.join(''), { length: 10 });
            },
          },

          annotation: {
            annotations: {
              tppLine: {
                display: isNil(selectedTraitValues.tpp_target) ? false : true,
                type: 'line',
                yMin: selectedTraitValues.tpp_target,
                yMax: selectedTraitValues.tpp_target,
                borderColor: 'red',
                borderWidth: 2,
                label: {
                  display: true,
                  content: `${i18n.t('TPP target: ')()} ${selectedTraitValues.tpp_target}`,
                  position: 'start',
                  font: { size: 10 },
                  yAdjust: -10,
                },
              },
              ...lineAnnotations,
            },
          },
          title: {
            text: chartTitle,
            display: true,
          },
        },
        scales: {
          x: {
            type: 'category',
            //@ts-ignore
            labels: xAxisLabels,
            offset: true,
            title: {
              display: true,
              font: { size: 15 },
              text: `${selectedTraitValues.site_dimension_name}`,
            },
          },
          y: {
            title: {
              display: true,
              font: { size: 15 },
              text: `${selectedTraitValues.trait_name} ${i18n.t(['mean_lowercase', 'mean'])()}`,
            },
            beginAtZero: true,
            max: maxYTickValue,
          },
        },
      },
    };
    this.currentBarChart(config);
  };

  getSiteZoneCode = (siteId: string) => {
    const site = this.trialSitesOptions().find((site: sitesApi.SiteData) => site.id === siteId);
    if (!site?.geographical_zones || !site?.geographical_zones[this.currentSiteGeoZoneType()]) {
      return;
    }
    return site.geographical_zones[this.currentSiteGeoZoneType()].grid_code;
  };
  getColorForSite = (siteId: string) => {
    const zoneCode = this.getSiteZoneCode(siteId);
    if (!zoneCode) {
      return;
    }
    return this.zonesColorsByZoneGridCode()[zoneCode];
  };
  reLoadCompareToControlPlot = () => {
    this.currentCompareToControlCharts([]);
    this.loadCompareToControlPlot();
  };

  loadCompareToControlPlot = (): void => {
    this.currentCompareToControlCharts([]);
    const selectedTraitValues = this.traitValuesForCompareToControlPlot();
    const allTestSubject = selectedTraitValues?.unique_test_subject_dimensions;
    if (!selectedTraitValues || selectedTraitValues?.values?.length === 0 || !allTestSubject) {
      return;
    }
    const siteAliases = this.compareToControlPlotSiteAliases();
    allTestSubject.forEach((test_subject_dimensions_ids: string[], index) => {
      let testSubjectData: TraitForPlotEntity[] = selectedTraitValues.values.filter(
        (entry) => entry.dimensions_ids.sort().join('') == test_subject_dimensions_ids.sort().join('')
      );

      if (testSubjectData.length === 0) {
        return;
      }

      const xyValues: any[] = testSubjectData.map((testSubjectEntry) => {
        return {
          x: siteAliases[testSubjectEntry.site_id].site_alias,
          y: testSubjectEntry.value,
          siteId: testSubjectEntry.site_id,
        };
      });
      const xAxisLabels: string[] = Array.from(new Set(xyValues.map((entry) => entry.x)));
      const barsColors: string[] = [];
      xyValues.forEach((entry) => {
        const siteWithExtraData = this.selectedSitesComputed().find(
          (site: sitesApi.SiteData) => site.id === entry.siteId
        );
        const zoneCodeOfSite =
          siteWithExtraData?.geographical_zones?.[this.currentSiteGeoZoneType()]?.grid_code;
        barsColors.push(this.zonesColorsByZoneGridCode()[zoneCodeOfSite]);
        entry.zoneCode = zoneCodeOfSite;
      });

      const dataset: ChartDataset = {
        pointRadius: 5,
        data: xyValues,
        backgroundColor: this.isZoneDataAvailable() ? barsColors : getColor(index),
        minBarLength: 5,
      };
      const chartTitle = `${testSubjectData[0].dimensions.map((dim) => dim.name).join(', ')}`;
      const config: ChartConfiguration = {
        type: 'bar',
        data: {
          datasets: [dataset],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'category',
              //@ts-ignore
              labels: xAxisLabels,
              offset: true,
            },
            y: {
              title: {
                display: true,
                font: { size: 15 },
                text: `${i18n.t('mean - control')()} ${i18n.t(['mean_lowercase', 'mean'])()}`,
              },
              beginAtZero: true,
              max: selectedTraitValues.max_value,
              min: Math.min(0, selectedTraitValues.min_value),
            },
          },
          plugins: {
            title: {
              text: chartTitle,
              display: false,
            },
            tooltip: {
              position: 'nearest',
              callbacks: {
                label: (context) => {
                  const tooltip = [
                    `${
                      Object.values(siteAliases).find((site) => site.site_alias === context.label).site_name
                    }: ${context.parsed.y}`,
                  ];
                  if ((context.dataset.data[context.dataIndex] as any).zoneCode !== undefined) {
                    tooltip.push(`Zone code: ${(context.dataset.data[context.dataIndex] as any).zoneCode}`);
                  }
                  return tooltip;
                },
              },
            },
            legend: {
              display: false,
            },
          },
        },
      };

      this.currentCompareToControlCharts.push(config);
    });
  };

  areDimensionArraysEqual(
    dim1: { name: string; control: boolean }[],
    dim2: { name: string; control: boolean }[]
  ) {
    const dim1Names = [...dim1]
      .sort((a, b) => (a.name > b.name ? -1 : 1))
      .map((d) => d.name)
      .join();
    const dim2Names = [...dim2]
      .sort((a, b) => (a.name > b.name ? -1 : 1))
      .map((d) => d.name)
      .join();
    return dim1Names == dim2Names;
  }
  getDatasetsSitesAsXAxis(isBarChart = false): ChartDataset[] {
    const selectedTraitValues: TraitDataInteractionPlot = this.traitValuesForInteractionPlot();
    const allGenotypes: InteractionPlotDimension[][] = [];
    selectedTraitValues.values.forEach((entry) => {
      if (!allGenotypes.some((dimensions) => this.areDimensionArraysEqual(dimensions, entry.dimensions))) {
        allGenotypes.push(entry.dimensions);
      }
    });

    const datasets: any[] = allGenotypes.map((dimensions: InteractionPlotDimension[], index) => {
      const xyValues: any[] = [];

      const valuesOfGenotype = selectedTraitValues.values.filter((entry) =>
        this.areDimensionArraysEqual(entry.dimensions, dimensions)
      );

      valuesOfGenotype.forEach((entry) => {
        xyValues.push({
          x: entry.site_name,
          y: entry.value,
          lsdLabels: entry.lsd_labels,
          site_id: entry.site_id,
        });
      });
      xyValues.sort((a, b) => a.x.localeCompare(b.x));
      let backgroundColor: string[] | string = getColor(index);
      let borderColor: string[] | string;
      borderColor = backgroundColor;
      if (isBarChart && dimensions.some((d) => d.control === true)) {
        borderColor = '#000000';
      }
      const dataset = {
        pointRadius: 5,
        data: xyValues,
        fill: false,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 3,
        label: dimensions.map((d) => (d.control ? `${d.name}*` : d.name)).join(' '),
        tension: 0,
      };
      return dataset;
    });

    return datasets;
  }

  getDatasetsOneDimensionAsXAxis(siteId: string): ChartDataset[] {
    const selectedTraitValues: TraitDataInteractionPlot = this.traitValuesForInteractionPlot();
    const traitValues = selectedTraitValues.values.filter((entry) => entry.site_id === siteId);

    const xAxisDimensionIndex = selectedTraitValues.trial_test_subject_dimension_metas.findIndex(
      (dim) => dim.id == this.selectedXAxisDimensionMetaId()
    );
    let legendDimensions: InteractionPlotDimension[][] = [];

    traitValues.forEach((entry) => {
      const dimensions = [...entry.dimensions];
      dimensions.splice(xAxisDimensionIndex, 1);
      legendDimensions.push(dimensions);
    });

    const yAxisDimensionsWithNoDuplicates: InteractionPlotDimension[][] = [];
    legendDimensions.forEach((dimensions) => {
      if (!yAxisDimensionsWithNoDuplicates.some((d) => this.areDimensionArraysEqual(d, dimensions))) {
        yAxisDimensionsWithNoDuplicates.push(dimensions);
      }
    });

    const datasets: ChartDataset[] = yAxisDimensionsWithNoDuplicates.map((dimensions, index) => {
      let xyValues: Object[] = [];

      const valuesOfGenotype = traitValues.filter((entry) => {
        const dimsToCompare = entry.dimensions.filter((dim, index) => index !== xAxisDimensionIndex);
        return isEqual(dimsToCompare, dimensions);
      });
      valuesOfGenotype.forEach((entry) => {
        const xLabel = entry.dimensions[xAxisDimensionIndex].control
          ? `${entry.dimensions[xAxisDimensionIndex].name}*`
          : entry.dimensions[xAxisDimensionIndex].name;
        xyValues.push({ x: xLabel, y: entry.value, lsdLabels: entry.lsd_labels });
      });

      const dataset: ChartDataset = {
        pointRadius: 5,
        data: xyValues as ScatterDataPoint[],
        fill: false,
        backgroundColor: getColor(index),
        borderColor: getColor(index),
        borderWidth: 3,
        label: dimensions.map((d) => (d.control ? `${d.name}*` : d.name)).join(', '),
        tension: 0,
      };
      return dataset;
    });

    return datasets;
  }

  selectReportDocumentTab = () => {
    location.href = session.toTenantPath(`/dashboard/${this.trial.id()}/reports`);
  };

  getAiContextDataForOverview = () => {
    const overviewTable = document.querySelector<HTMLTableElement>('.overview-table');

    // Base context with trial information
    const contextData: Record<string, any> = {
      'Trial Name': this.overviewData.trial_name,
      'Trial Data': new OverviewExporter(
        overviewTable,
        this.overviewData,
        this.overviewSparseData
      ).exportToJson(),
      'Information about the trial data':
        'Each contains the value measured for a trait and a combination of dimensions. ' +
        'There are plot dimensions, such as site, replication, and test subjects. ' +
        'There are also visits, which indicate when the data was collected. ' +
        'Trait groups define the collection level of the trait. ' +
        'Example: when trait group indicates "Site", it means that the trait value is collected once per site.',
      traits: this.trial.traits(),
      sites: this.trial.sites(),
    };

    return contextData;
  };

  getFeedbackContextData = (): FeedbackContextData => {
    return {
      tags: {
        trialId: this.trial.id(),
      },
      contexts: {
        page: 'Overview',
      },
    };
  };
}

export let trialFacts = {
  name: 'trial-facts',
  viewModel: TrialFactsScreen,
  template: template,
};

ko.components.register(trialFacts.name, trialFacts);

export interface OverviewTableViewDelegate {
  units: UnitData[];
  measurementMetasUnits: Record<number, number>;
  readOnly: boolean;

  onObservation?(rowIdx: number, colIdx: number): void;
  onEditInline?(value: string): void;
  onMove?(direction: DataEntryMoveDirection): void;
  openMap?(value: {}): void;
  openPictures?(urls: string[]): void;
  openVideo?(url: string): void;
  editDerivedTrait?(scheduledVisitId: string, datasetId: string, mmId: string): void;
  reload?(): void;
}

const headerRowHeight = 32; // in pixels, must keep in sync with CSS
const plotDetailHeight = 15; // pixels, must keep in sync with CSS

// rendering this table with KO is just too slow
export class OverviewTableView {
  readonly root = el('table');
  private colgroup = el('colgroup');
  private thead = el('thead');
  private tbody = el('tbody');

  private input = el('input');
  private allowChangeOfUnits: boolean = false;

  private removeListeners = (): void => null;
  private reusableViews: {
    root: HTMLElement;
    update: (
      data: dashboardApi.OverviewData,
      sparseData: OverviewSparseData,
      editModel: DataEntryEditModel | null,
      row: number,
      hasInputFocus: boolean
    ) => void;
  }[] = [];
  private rendering = false;
  private hasInputFocus = false;
  private virtualScroll: () => void = null;
  private lastScrollYPosition: number | null = null;
  private changedInputs: HTMLElement[] = [];

  constructor(public delegate: OverviewTableViewDelegate, allowChangeOfUnits: boolean) {
    this.root.classList.add('overview-table');
    this.root.appendChild(this.colgroup);
    this.root.appendChild(this.thead);
    this.root.appendChild(this.tbody);
    this.allowChangeOfUnits = allowChangeOfUnits;

    this.input.type = 'text';

    this.input.addEventListener('keydown', (evt: KeyboardEvent) => {
      const key = evt.which || evt.keyCode;

      if (key === KEYCODE_DOWN || key == KEYCODE_ENTER) {
        this.input.blur();
        delegate.onMove?.('down');
        evt.preventDefault();
      } else if (key === KEYCODE_UP) {
        this.input.blur();
        delegate.onMove?.('up');
        evt.preventDefault();
      } else if (key === KEYCODE_LEFT) {
        this.input.blur();
        delegate.onMove?.('left');
        evt.preventDefault();
      } else if (key === KEYCODE_RIGHT || key === KEYCODE_TAB) {
        this.input.blur();
        delegate.onMove?.('right');
        evt.preventDefault();
      }
    });
    this.input.addEventListener('focus', () => (this.hasInputFocus = true));
    this.input.addEventListener('blur', () => {
      if (this.changedInputs.includes(this.input)) {
        delegate.onEditInline?.(this.input.value);
        this.changedInputs = this.changedInputs.filter((input) => input !== this.input);
      }
      if (!this.rendering) {
        this.hasInputFocus = false;
      }
    });

    this.input.addEventListener('input', () => {
      this.changedInputs.push(this.input);
    });
  }

  dispose() {
    this.removeListeners();
  }

  focusInput() {
    // Set the hasInputFocus flag to true, so that when the input
    // is re-rendered by the .updateData() function, it'll become focused.
    this.hasInputFocus = true;
  }

  updateLoading(loading: boolean) {
    if (loading) {
      this.root.classList.add('loading');
    } else {
      this.root.classList.remove('loading');
    }
  }

  updateData(
    data: dashboardApi.OverviewData,
    sparseData: OverviewSparseData,
    canEditTrial: boolean,
    editModel: DataEntryEditModel | null
  ) {
    this.removeListeners();

    let scrollingElement: HTMLElement = this.root;
    while (scrollingElement && !scrollingElement.classList.contains('overview-table-container')) {
      scrollingElement = scrollingElement.parentElement;
    }
    this.rendering = true;

    this.thead.innerHTML = '';
    this.colgroup.innerHTML = '';
    this.tbody.innerHTML = '';
    this.reusableViews = [];

    for (let i = 0; i < data.mm_ids.length + 1; i++) {
      this.colgroup.appendChild(el('col'));
    }

    let headerGroupIdx = 0;

    let previousHeaderWasLarge = false;
    let topValue = -headerRowHeight; // To make sure it starts at 0
    for (let group of data.header_groups) {
      let headerGroupRow = el('tr');

      topValue += headerRowHeight;
      if (previousHeaderWasLarge) {
        previousHeaderWasLarge = false;
        topValue += headerRowHeight;
      }

      let top = topValue + 'px';
      let groupTitle = el('th');
      groupTitle.style.top = top;

      groupTitle.title = group.title;
      let groupTitleContent = el('div', 'overview-header-group-title');

      let groupTitleText = el('span');
      groupTitleText.textContent = group.title;
      let arrowRight = el('i', 'material-icons');
      arrowRight.textContent = 'chevron_right';

      groupTitleContent.appendChild(groupTitleText);
      groupTitleContent.appendChild(arrowRight);
      groupTitle.appendChild(groupTitleContent);
      headerGroupRow.appendChild(groupTitle);

      let colIdx = 0;
      for (let header of group.headers) {
        let headerElem = el('th');
        headerElem.setAttribute('colspan', header.size.toString());
        headerElem.style.top = top;

        let title = header.title;
        const validation = data.validations[data.mm_ids[colIdx]];
        if (validation && validation.options && validation.rating) {
          title += '\n\n';
          for (let opt of validation.options) {
            title += `${opt.name}: ${opt.value.toLocaleString()}\n`;
          }
        }
        headerElem.title = title;

        const valueType = data.value_types[colIdx];
        const editDerived =
          headerGroupIdx === data.header_group_mm_idx &&
          (valueType === 'date_derived' || valueType === 'number_derived');
        const addDerived = headerGroupIdx === data.header_group_ds_idx;
        const errors = data.errors[colIdx];

        if (canEditTrial && (editDerived || addDerived)) {
          const scheduledVisitId = data.scheduled_visit_ids[colIdx];
          const datasetId = data.dataset_ids[colIdx];
          const mmId = editDerived ? data.mm_ids[colIdx] : null;

          const container = el('div');
          container.className = 'overview-header-edit-action';

          const title = el('span');
          title.textContent = header.title;

          const editIcon = el('i');
          editIcon.className = 'material-icons';
          editIcon.textContent = 'calculate';
          editIcon.onclick = () => this.delegate.editDerivedTrait?.(scheduledVisitId, datasetId, mmId);

          container.appendChild(title);
          container.appendChild(editIcon);
          headerElem.appendChild(container);
        } else {
          if (header.unit_id !== null && this.allowChangeOfUnits) {
            const container = el('div');
            previousHeaderWasLarge = true;
            groupTitle.style.height = headerRowHeight * 2 + 'px';
            container.classList.add('overview-header-edit-action', 'with-unit-switch');
            const title = el('span');
            title.textContent = header.title;
            container.appendChild(title);

            let selectedUnitId =
              this.delegate.measurementMetasUnits[header.measurement_meta_id] || header.unit_id;
            let selectedUnitData = this.delegate.units.find(
              (unit: UnitData) => unit.id === selectedUnitId.toString()
            );
            let sameCategoryUnits = this.delegate.units.filter(
              (unit: UnitData) => unit.unit_category === selectedUnitData.unit_category
            );

            this.delegate.measurementMetasUnits[header.measurement_meta_id] = selectedUnitId;
            const unitsDropdown = document.createElement('select');
            unitsDropdown.className = 'unit-select';
            unitsDropdown.onchange = async (event) => {
              const newUnitId = (event.target as HTMLSelectElement).value;
              const traitsIdsToUpdate: Set<number> = new Set([header.measurement_meta_id]);

              const shouldApplyChangesToAllColumns: boolean = (await showApplyUnitChangesToAllColumnsDialog(
                selectedUnitData.description,
                sameCategoryUnits.find((unit) => unit.id === newUnitId).description
              )) as boolean;

              if (shouldApplyChangesToAllColumns) {
                const initialUnitId = this.delegate.measurementMetasUnits[header.measurement_meta_id];

                for (let key of Object.keys(this.delegate.measurementMetasUnits)) {
                  const numericKey = parseInt(key);
                  if (this.delegate.measurementMetasUnits[numericKey] === initialUnitId) {
                    traitsIdsToUpdate.add(numericKey);
                  }
                }
              }

              for (let traitId of Array.from(traitsIdsToUpdate)) {
                setUnitPreferenceForTrait(traitId.toString(), newUnitId);
                this.delegate.measurementMetasUnits[traitId] = parseInt(newUnitId);
              }

              this.delegate.reload();
            };

            // Reset to default option is a special case
            unitsDropdown.append(
              createSelectOption(header.unit_id.toString(), i18n.t('Reset to default')(), false)
            );
            for (let unitData of sameCategoryUnits) {
              unitsDropdown.append(createUnitDropdownOption(unitData, selectedUnitData.id));
            }

            container.appendChild(unitsDropdown);
            headerElem.append(container);
          } else {
            let title = header.title;
            if (header.measurement_meta_id && header.measurement_meta_id && header.unit_id) {
              const unitData = this.delegate.units.find(
                (unit: UnitData) => unit.id === header.unit_id.toString()
              );
              title = `${header.title} (${unitData.name})`;
            }
            headerElem.textContent = title;
          }
        }
        if (headerGroupIdx === data.header_group_mm_idx && errors.length > 0) {
          headerElem.classList.add('overview-header-has-errors');

          const errorsContainer = el('div', 'overview-header-errors-container');
          const errorsElem = el('div', 'overview-header-errors');
          for (let error of errors) {
            const elem = el('div');
            elem.textContent = error;
            errorsElem.appendChild(elem);
          }
          errorsContainer.appendChild(errorsElem);
          headerElem.appendChild(errorsContainer);
        }

        headerGroupRow.appendChild(headerElem);
        colIdx += header.size;
      }

      this.thead.appendChild(headerGroupRow);
      headerGroupIdx++;
    }

    const nPlotDetails = data.rows[0]?.plot_dimensions.length ?? 0;
    const nCols = data.mm_ids.length;
    const rowHeight = Math.min(nPlotDetails, 2) * plotDetailHeight;
    const maxInvisibleTopRows = 10;
    const maxRenderedRows = Math.ceil(window.innerHeight / rowHeight) + maxInvisibleTopRows;

    const virtualTop = document.createElement('tr');
    const virtualBottom = document.createElement('tr');

    this.tbody.appendChild(virtualTop);
    for (let i = 0; i < Math.min(maxRenderedRows, data.rows.length); i++) {
      this.reusableViews.push(rowView(nPlotDetails, nCols, this.input, this.delegate));
      this.tbody.appendChild(this.reusableViews[i].root);
    }
    this.tbody.appendChild(virtualBottom);

    this.rendering = false;

    let updatingScroll = false;
    this.virtualScroll = () => {
      const scrollPosition: number | null = scrollingElement ? scrollingElement.scrollTop : null;
      this.lastScrollYPosition = scrollPosition;
      if (!updatingScroll && scrollPosition != null) {
        window.requestAnimationFrame(() => {
          const virtualTopRows = Math.max(
            0,
            Math.min(
              data.rows.length - maxRenderedRows,
              Math.floor(scrollPosition / rowHeight) - maxInvisibleTopRows
            )
          );
          const virtualBottomRows = Math.max(0, data.rows.length - virtualTopRows - maxRenderedRows);

          virtualBottom.style.height = `${virtualBottomRows * rowHeight}px`;
          virtualTop.style.height = `${virtualTopRows * rowHeight}px`;

          this.rendering = true;
          for (let idx = 0; idx < this.reusableViews.length; idx++) {
            this.reusableViews[idx].update(
              data,
              sparseData,
              editModel,
              idx + virtualTopRows,
              this.hasInputFocus
            );
          }
          this.rendering = false;

          updatingScroll = false;
        });
        updatingScroll = true;
      }
    };

    // Scroll is lost after reloading if user scrolled down more than rendered number of rows. This seems to be
    // because of reusing the rows. Fix: store last scroll position and manually scroll there after reload.
    if (scrollingElement && this.lastScrollYPosition !== null) {
      const scrollToY = this.lastScrollYPosition;
      setTimeout(() => {
        scrollingElement.scroll({ top: scrollToY, behavior: 'auto' });
      });
    }

    this.virtualScroll();
    if (isIE11()) {
      scrollingElement?.addEventListener('scroll', this.virtualScroll);
    } else {
      scrollingElement?.addEventListener('scroll', this.virtualScroll, {
        passive: true,
      });
    }
    this.removeListeners = () => scrollingElement?.removeEventListener('scroll', this.virtualScroll);
  }

  /**
   * Forces updateDataContents to recreate the basic structure.
   *
   * To be called if the columns or row have changed.
   */
  clearStructure() {
    this.virtualScroll = null;
  }

  /**
   * Same as updateData, but after the first invocation assumes that the table layout and headers don't change,
   * and that the only thing changing are the cells contents. It also assumes that data/sparseData/canEditTrial/editModel
   * are the same object references passed to the first invocation.
   */
  updateDataContents(
    data: dashboardApi.OverviewData,
    sparseData: OverviewSparseData,
    canEditTrial: boolean,
    editModel: DataEntryEditModel | null
  ) {
    if (this.virtualScroll) {
      this.virtualScroll();
    } else {
      this.updateData(data, sparseData, canEditTrial, editModel);
    }
  }
}

function rowView(
  nPlotDetails: number,
  nCols: number,
  input: HTMLInputElement,
  delegate: OverviewTableViewDelegate
) {
  const root = el('tr');
  const header = plotHeader(nPlotDetails);
  const cells = rowCells(nCols, input, delegate);
  root.appendChild(header.root);
  for (let cellRoot of cells.roots) {
    root.appendChild(cellRoot);
  }

  return {
    root,
    update: (
      data: dashboardApi.OverviewData,
      sparseData: OverviewSparseData,
      edit: DataEntryEditModel | null,
      row: number,
      hasInputFocus: boolean
    ) => {
      header.update(data.rows[row]);
      root.dataset.plotId = data.rows[row].plot_id;
      cells.update(data, sparseData, edit, row, hasInputFocus);
    },
  };
}

function plotHeader(nPlotDetails: number) {
  let plotHeader = el('td');
  let plotHeaderContent = el('div', 'overview-plot-header');
  let plotHeaderNameContainer = el('div', 'overview-plot-header-name-container');
  let plotName = el('div');
  let plotTreatmentName = el('div');
  let plotDims = el('div');

  for (let i = 0; i < nPlotDetails; i++) {
    plotDims.appendChild(el('div'));
  }

  plotHeaderNameContainer.appendChild(plotName);
  plotHeaderNameContainer.appendChild(plotTreatmentName);
  plotHeaderContent.appendChild(plotHeaderNameContainer);
  plotHeaderContent.appendChild(plotDims);
  plotHeader.appendChild(plotHeaderContent);

  return {
    root: plotHeader,
    update: (row: dashboardApi.OverviewDataRow) => {
      let plotTitle = row.plot_name;
      plotName.textContent = row.plot_name;
      plotTreatmentName.textContent = row.plot_treatment_name;
      plotTreatmentName.title = row.plot_treatment_name;
      row.plot_dimensions.forEach((dim, idx) => {
        plotDims.children[idx].textContent = translate(dim);
        plotTitle += ` ${translate(dim)}`;
      });
      plotHeaderContent.title = plotTitle.trim();
    },
  };
}

function rowCells(nCols: number, input: HTMLInputElement, delegate: OverviewTableViewDelegate) {
  let roots: HTMLElement[] = [];
  let contents: {
    link: HTMLElement;
    text: HTMLElement;
    which: 'link' | 'text' | 'input';
    action: () => void;
  }[] = [];

  for (let colIdx = 0; colIdx < nCols; colIdx++) {
    const valueCell = el('td');

    const commentExtraMarker = el('span', 'comment-extra-marker');
    const pictureOrDocumentExtraMarker = el('span', 'picture-or-document-extra-marker');
    const editedMarker = el('span', 'edited-marker');
    valueCell.append(editedMarker, commentExtraMarker, pictureOrDocumentExtraMarker);

    const content = {
      link: el('a'),
      text: el('div'),
      which: 'text' as 'text',
      action: null as () => void,
    };

    content.link.textContent = i18n.t('View')();
    valueCell.onclick = () => content.action?.();
    valueCell.appendChild(content.text);

    roots.push(valueCell);
    contents.push(content);
  }

  return {
    roots,
    update: (
      data: dashboardApi.OverviewData,
      sparseData: OverviewSparseData,
      edit: DataEntryEditModel | null,
      rowIdx: number,
      hasInputFocus: boolean
    ) => {
      for (let colIdx = 0; colIdx < nCols; colIdx++) {
        const value = sparseData.valueAt(sparseData.values, rowIdx, colIdx);
        const historicValue = sparseData.valueAt(sparseData.historic_values, rowIdx, colIdx);
        const reasonValue = sparseData.valueAt(sparseData.reason_values, rowIdx, colIdx)?.toString();

        const valueCell = roots[colIdx];
        const content = contents[colIdx];

        const type = data.value_types[colIdx];
        const validation = data.validations[data.mm_ids[colIdx]];
        const emptyValue =
          value === null ||
          value === undefined ||
          value === '' ||
          (type === 'multi_pictures' && getMultiPicturesUrls(value).length === 0);

        const emptyHistoryValue =
          historicValue === null ||
          historicValue === undefined ||
          historicValue === '' ||
          (type === 'multi_pictures' && getMultiPicturesUrls(historicValue).length === 0);

        const isDataEntryAllowed = sparseData.isDataEntryAllowedAt(rowIdx, colIdx);

        const canEdit = edit && edit.canEdit(rowIdx, colIdx) && isDataEntryAllowed;
        const factId = sparseData.idAt(rowIdx, colIdx);

        if (canEdit || !emptyValue || (emptyValue && !emptyHistoryValue)) {
          content.action = () => delegate.onObservation?.(rowIdx, colIdx);
        } else {
          content.action = null;
        }

        // overview page, derived trait without factId.
        if (delegate.readOnly && factId == undefined) {
          content.action = null;
        }

        valueCell.classList.toggle('can-edit', canEdit);
        valueCell.classList.toggle('clickable-cell', !emptyHistoryValue);
        valueCell.classList.toggle('inline-validation-error', !!edit?.validationError(rowIdx, colIdx));
        valueCell.classList.toggle(
          'loading-cell',
          !!edit?.isRowColChanged(factId, data.mm_ids[colIdx]) && !edit?.validationError(rowIdx, colIdx)
        );
        valueCell.classList.toggle('data-entry-not-allowed', !isDataEntryAllowed);
        // Always remove these classes because cells are reused as user scrolls down
        valueCell.classList.remove('with-comment-extra-marker');
        valueCell.classList.remove('with-picture-or-document-extra-marker');
        valueCell.classList.remove('value-edited-marker');

        valueCell.setAttribute('rowIdx', rowIdx.toString());
        valueCell.setAttribute('colIdx', colIdx.toString());

        // add references to update styles in future
        valueCell.setAttribute('fact_id', factId);

        const measurementMetaId = data.mm_ids[colIdx];
        valueCell.setAttribute('mm', measurementMetaId);

        // Fact ID can be undefined
        if (factId) {
          const commentExtrasForFact = data['fact_ids_with_comment_extras'][factId];
          if (
            commentExtrasForFact &&
            (commentExtrasForFact.includes(null) ||
              commentExtrasForFact.includes(parseInt(measurementMetaId))) &&
            value != undefined &&
            value != null
          ) {
            valueCell.classList.add('with-comment-extra-marker');
          }

          const pictureOrDocumentExtrasForFact = data['fact_ids_with_picture_or_document_extras'][factId];
          if (
            pictureOrDocumentExtrasForFact &&
            (pictureOrDocumentExtrasForFact.includes(null) ||
              pictureOrDocumentExtrasForFact.includes(parseInt(measurementMetaId))) &&
            value != undefined &&
            value != null
          ) {
            valueCell.classList.add('with-picture-or-document-extra-marker');
          }

          // Show the "edited" markers in the Overview screen only. Users can't see history in the data entry screen.
          if (delegate.readOnly) {
            const editedMeasurementMetaIds = data['fact_ids_with_edited_observations'][factId];
            if (editedMeasurementMetaIds && editedMeasurementMetaIds.includes(parseInt(measurementMetaId))) {
              valueCell.classList.add('value-edited-marker');
            }
          }
        }

        if (edit && edit.isSelected(rowIdx, colIdx)) {
          const canEditInline = edit.canEditInline(rowIdx, colIdx);
          if (canEditInline) {
            input.value = (value ?? '').toString();
          } else {
            input.value = formatValue(type, validation, value);
          }
          input.readOnly = !canEditInline;
          if (content.which !== 'input') {
            removeIfChild(valueCell, content.text);
            removeIfChild(valueCell, content.link);
            valueCell.appendChild(input);
            content.which = 'input';
          }

          if (hasInputFocus) {
            input.focus();
          }
        } else if (
          !emptyValue &&
          !edit &&
          (type === 'geo' ||
            type === 'location' ||
            type === 'picture' ||
            type === 'video' ||
            type === 'signature' ||
            type === 'file' ||
            type === 'multi_pictures')
        ) {
          if (type === 'geo' || type === 'location') {
            content.action = () => delegate.openMap?.(value);
          } else if (type === 'multi_pictures') {
            content.action = () => delegate.openPictures?.(getMultiPicturesUrls(value));
          } else if (type === 'file') {
            content.link.textContent = i18n.t('Download')();
            content.link.setAttribute('href', (value as any)?.url);
            content.link.setAttribute('download', '');
            content.link.setAttribute('target', '_blank');
            content.action = () => content.link.click();
          } else if (type == 'video') {
            content.action = () => delegate.openVideo?.((value as any)?.url);
          } else {
            content.action = () => delegate.openPictures?.([(value as any)?.url]);
          }
          if (content.which !== 'link') {
            removeIfChild(valueCell, content.text);
            removeIfChild(valueCell, input);
            valueCell.appendChild(content.link);
            content.which = 'link';
          }
        } else {
          content.text.textContent = formatValue(type, validation, value);
          content.text.classList.toggle('overview-empty-value', emptyValue);
          content.text.classList.toggle('overview-have-history-value', emptyValue && !emptyHistoryValue);
          // if value is empty, but have history show latest changereason
          if (emptyValue && !emptyHistoryValue) {
            content.text.textContent = formatValue(
              type,
              validation,
              factChangeReasons.find((c) => c.value === reasonValue)?.title
            );
          }
          if (content.which !== 'text') {
            removeIfChild(valueCell, content.link);
            removeIfChild(valueCell, input);
            valueCell.appendChild(content.text);
            content.which = 'text';
          }
        }
      }
    },
  };
}
