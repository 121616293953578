import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoader, ListLoaderDelegate } from '../components/list_loader';
import { Group } from '../models/group';
import * as groupsApi from '../api/groups';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { canEditGroup } from '../permissions';
import { updateLocationWithQueryString } from '../utils';

let template = require('raw-loader!../../templates/groups.html').default;

class GroupsScreen implements ListLoaderDelegate<groupsApi.BaseGroupData, Group> {
  loader: ListLoader<groupsApi.BaseGroupData, Group>;
  canEdit = canEditGroup();
  listActions = ko.observableArray<ListHeaderAction>([]);

  searchPlaceholder = i18n.t('Search groups by name')();
  search = ko.observable('').extend({ rateLimit: { timeout: 400, method: 'notifyWhenChangesStop' } });

  constructor(params: { filters: { search: string } }) {
    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/groups/new/',
        tooltipTitle: i18n.t('Add group')(),
      });
    }

    if (params.filters.search) {
      this.search(params.filters.search);
    }
  }

  onReady(loader: ListLoader<groupsApi.GroupData, Group>): void {
    this.loader = loader;
    this.search.subscribe(() => this.loader.forceLoad());
  }

  fetch(params: ListRequestParams) {
    const filters = {
      search: this.search(),
    };
    updateLocationWithQueryString(filters);

    return groupsApi.list({
      ...filters,
      ...params,
    });
  }

  instantiate(groupData: groupsApi.BaseGroupData) {
    return new Group(false, groupData);
  }

  remove(id: string) {
    return groupsApi.remove(id);
  }

  canRemove(group: Group) {
    return this.canEdit;
  }
}

export let groups = {
  name: 'groups',
  viewModel: GroupsScreen,
  template: template,
};

ko.components.register(groups.name, groups);
